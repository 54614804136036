import { setError, setLoading } from "../utils";
import { nextPrev } from "../utils/StepService";
import { request } from "../utils/axios";
import { getSessionId, getShadowRoot, setSessionData } from "../utils/store";

class MobileVerificationInitiated {
  render() {
    let modalBox = getShadowRoot().querySelector("#identity-modal__box");

    if (modalBox.querySelector("#mobileVerificationInitiated")) return;

    modalBox.insertAdjacentHTML(
      "beforeend",
      `<div class="form-content mobile-verification-qr" id="mobileVerificationInitiated" data-form-tab>
                <div class="identity__row">
                    <div class="identity__col-12">
                      <div class="identity-alert__error" id="identity-alert__error" style="display:none;">
                        <button data-close="close" style="display:none;" type="button">&times;</button>
                        <svg class="icon" width="20px" height="20px" aria-hidden="true" role="img"><use href="#svg__alert-error" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__alert-error"></use></svg>
                        <div id="identity-alert__message" data-text="text"></div>
                      </div>
                    </div>
                    <div class="identity__col-12 identity__text-center">
                        <h3 class="identity__h3">Mobile Verification</h3>
                    </div>
                    <div class="identity__col-12 identity__text-center">
                        <p>We've sent a link to your phone. Please open it to continue verification from phone</p>
                    </div>
                    <div class="identity__col-12 identity__text-center">
                        <div class="identity-field">
                            <svg class="svg" width="62.454" height="105.691" aria-hidden="true" role="img">
                                <use href="#svg__phone-success" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__phone-success">
                                </use>
                            </svg>
                        </div>
                    </div>
                    <div class="identity__col-12 identity__text-center">
                        <p>Click the button below when you have finished the verifiation in mobile.</p>
                    </div>
                    <div class="identity__col-12 identity__text-center">
                        <button class="identity-btn" type="button" id="identity__finishedBtn">
                            I HAVE FINISHED
                            <span class="loading-icon">
                            <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img">
                              <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                            </svg>
                          </span>
                        </button>
                    </div>
                </div>
            </div>
            `
    );

    this.bindEvents();
  }

  bindEvents() {
    let finishedButton = getShadowRoot().querySelector(
      "#identity__finishedBtn"
    );
    finishedButton.addEventListener("click", async () => {
      setLoading(true);
      try {
        let resp = await request.get(`/Details/${getSessionId()}`);
        if (resp && resp.data && resp.data.data) {
          if (
            resp.data.data.nextVerificationStep === "Success" ||
            resp.data.data.nextVerificationStep === "Failure"
          ) {
            await setSessionData({...resp.data.data});
            await nextPrev(1);
          } else {
            setError(
              "It seems you haven't completed the verification from phone. Please complete the verification and try again."
            );
          }
        }
      } catch {
        setError();
      } finally {
        setLoading(false);
      }
    });
  }
}

export default MobileVerificationInitiated;
