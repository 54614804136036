import { nextPrev, setCurrentStep } from "../utils/StepService";
import { setLoading } from "../utils";
import { getShadowRoot } from "../utils/store";

class UploadDocumentSelection {
  render() {
    let modalBox = getShadowRoot().querySelector("#identity-modal__box");

    if (modalBox.querySelector("#uploadDocumentSelectionContent")) return;

    modalBox.insertAdjacentHTML(
      "beforeend",
      `<div class="form-content upload-document-selection" method="post" id="uploadDocumentSelectionContent" data-form-tab>
        <div class="identity__row">
          <div class="identity__col-12 identity__text-center">
            <h3 class="identity__h3">Photo ID</h3>
          </div>
        </div>
        <div class="identity__row">
          <div class="identity__col-12 identity__text-center">
            <p> You must submit a picture of your driver's license, ensuring that your name, date of birth, and expiration date are clearly visible. </p>
            <p> The ID verification process typically takes about 20 seconds. </p>
          </div>
        </div>
        <div class="identity__row">
          <div class="identity__col-12 identity__text-center">
            <div class="identity-field">
              <svg class="svg" width="135.844" height="105.656" aria-hidden="true" role="img">
                <use href="#svg__id-card" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__id-card" fill="#183956">
                </use>
              </svg>
            </div>
          </div>
        </div>
        <div class="identity__row">
          <div class="identity__col-12 identity__text-center"> 
          </div>
          <div class="identity__col-12 identity__text-center">
            <button type="button" class="identity-btn identity-btn__primary" id="btnUsePhone">
             Continue
              <span class="loading-icon">
                <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img">
                  <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>`
    );
        this.bindEvents()
  }

  bindEvents(){
    let usePhoneBtn = getShadowRoot().querySelector("#btnUsePhone");
    usePhoneBtn.addEventListener("click",async ()=>{
        setLoading(true);
        await setCurrentStep("mobileVerificationQR")
        await nextPrev(1);
        setLoading(false);
    })
  }
}

export default UploadDocumentSelection;
