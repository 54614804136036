import FormValidator from "./FormValidator";
import { request } from "../utils/axios";
import { getSessionId, setSessionData, getSessionData, getShadowRoot } from "../utils/store";
import { nextPrev } from "../utils/StepService";
import { setLoading, setError } from "../utils";

class VerifyID {
  validator = null;
  render() {
    let modalBox = getShadowRoot().querySelector("#identity-modal__box");
    if (modalBox.querySelector("#personalInfoContent")) return;

    modalBox.insertAdjacentHTML(
      "beforeend",
      `<form class="form-content" id="verifyIDContent" data-form-tab>
        <h3>Verify ID</h3>
        <div class="identity__row">
            <div class="identity__col-12">
            <div class="identity-alert__error" id="identity-alert__error" style="display:none;">
                <button data-close="close" style="display:none;" type="button">&times;</button>
                <svg class="icon" width="20px" height="20px" aria-hidden="true" role="img"><use href="#svg__alert-error" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__alert-error"></use></svg>
                <div data-text="text" id="identity-alert__message"></div>
            </div>
        </div>
        <div class="identity__row">
            <div class="identity__col-12">
                <div class="identity-field floating-field">
                    <input name="firstName" id="firstName" type="text" class="identity-input input" placeholder="none" maxlength="50" onkeypress="return /^[a-zA-Z]*$/i.test(event.key)" />
                    <label for="firstName" class="identity-floating-label">First Name*</label>
                    <svg class="icon icon-error " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                    </svg>
                    <span class="error-message"></span>
                </div>
            </div>
            <div class="identity__col-12">
                <div class="identity-field last">
                    <button id="btn-continue" type="submit" class="identity-btn btn-continue">REVERIFY
                        <span class="loading-icon">
                            <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img">
                            <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                            </svg>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </form>`
    );

    this.bindEvents();
  }

  bindEvents() {
    const sessionData = getSessionData();
    setTimeout(() => {
      if (sessionData.idVerificationMessage)
        setError(sessionData.idVerificationMessage);
    }, 100);
    const verifyIDForm = getShadowRoot().querySelector("#verifyIDContent");

    verifyIDForm.addEventListener("submit", (event) => {
      event.preventDefault();

      const fields = {
        firstName: {
          required: true,
        },
      };

      this.validator = new FormValidator(event.target, fields);

      let validData = this.validator.validate();
      if (validData.isValid) {
        setLoading(true);
        request
          .post(`/firstname/${getSessionId()}`, {
            ...validData.data,
          })
          .then(({ data }) => {
            setSessionData({
              showIdVerification: false,
              idVerificationMessage: null,
              ...data.data,
            });
            nextPrev(1);
          })
          .catch(({ response }) => {
            setError(response);
            setSessionData({
              showIdVerification: false,
              idVerificationMessage: null,
              idVerificationFailed: true,
            });
            nextPrev(1);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  }
}

export default VerifyID;
