class FormValidator {
  constructor(form, fields) {
    this.form = form;
    this.fields = fields;
    this.validateOnEntry();
  }

  isValidDate(date) {
    var matches = /^(\d{1,2})[-\/](\d{1,2})[-\/](\d{4})$/.exec(date);
    if (matches == null) return false;
    var d = matches[2];
    var m = matches[1] - 1;
    var y = matches[3];
    var composedDate = new Date(y, m, d);
    var TodayDate = new Date();
    return (
      composedDate.getDate() == d &&
      composedDate.getMonth() == m &&
      composedDate.getFullYear() == y &&
      composedDate.getFullYear() >= 1900 &&
      new Date(composedDate).getTime() <= TodayDate.getTime()
    );
  }

  validate() {
    let self = this,
      isValid = true,
      data = {},
      allFields = {};

    for (const key in self.fields) {
      const input = this.form.querySelector(`#${key}`);

      if (self.fields[key].required) {
        isValid = self.validateFields(input);
        allFields[key] = isValid;
      } else if (self.fields[key].requiredIf) {
        let isRequired = self.fields[key].requiredIf();
        if (isRequired) {
          isValid = self.validateFields(input);
        } else {
          const input = self.form.querySelector(`#${key}`);
          self.setStatus(input, null, "success");
        }
        allFields[key] = isValid;
      }
      data = {
        ...data,
        [input.name]: input.value,
      };

      // if (!isValid) break;
    }
    for (const field in allFields) {
      if (!allFields[field]) {
        isValid = false;
        break;
      }
    }

    return {
      isValid: !!isValid,
      data,
    };
  }

  validateOnEntry() {
    let self = this;

    for (const field in this.fields) {
      if (this.fields[field].required) {
        const input = this.form.querySelector(`#${field}`);

        input.addEventListener("input", (event) => {
          self.validateFields(input);
        });
      } else if (self.fields[field].requiredIf) {
        let isRequired = self.fields[field].requiredIf();
        if (isRequired) {
          const input = self.form.querySelector(`#${field}`);

          input.addEventListener("input", (event) => {
            self.validateFields(input);
          });
        } else {
          const input = self.form.querySelector(`#${field}`);
          self.setStatus(input, null, "success");
        }
      }
    }
  }

  validateFields(field) {
    let isValid = true;

    // Check presence of values
    if (field.value.trim() === "") {
      this.setStatus(field, `Field is Required!`, "error");
      isValid = false;
      return isValid;
    } else {
      this.setStatus(field, null, "success");
    }

    if (["firstName", "lastName"].includes(field.id)) {
      const re = /^(?=.{1,50}$)[a-zA-Z ]+(?:[-' ][a-zA-Z]+)*$/gm;
      if (re.test(field.value)) {
        this.setStatus(field, null, "success");
      } else {
        this.setStatus(field, "Please enter a valid name", "error");
        isValid = false;
      }
    }

    if (field.id === "dob") {
      var validDate = this.isValidDate(field.value);

      if (validDate) {
        this.setStatus(field, null, "success");
      } else {
        this.setStatus(field, "Invalid Date.", "error");
        isValid = false;
      }
    }

    // check for a valid email address
    if (field.name === "email") {
      // const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      const re =
        /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;

      if (re.test(field.value)) {
        this.setStatus(field, null, "success");
      } else {
        this.setStatus(field, "Please enter valid email address", "error");
        isValid = false;
      }
    }

    if (field.id === "password") {
      let isPasswordValid =
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(
          field.value
        );
      if (field.value.trim() == "") {
        this.setStatus(field, `Field is Required!`, "error");
        isValid = false;
      } else if (field.value.length < 8) {
        this.setStatus(
          field,
          "password should be atleast 8 characters long",
          "error"
        );
        isValid = false;
      } else if (!isPasswordValid) {
        this.setStatus(
          field,
          "Password must contain at least one upper and lowercase, one number, and one special character.",
          "error"
        );
        isValid = false;
      } else {
        this.setStatus(field, null, "success");
      }
    }

    // Password confirmation edge case
    if (field.id === "confirmPassword") {
      const passwordField = this.form.querySelector("#password");

      if (field.value.trim() == "") {
        this.setStatus(field, `Field is Required!`, "error");
        isValid = false;
      } else if (field.value != passwordField.value) {
        this.setStatus(field, "Password does not match", "error");
        isValid = false;
      } else {
        this.setStatus(field, null, "success");
      }
    }

    if (field.id === "phone" || field.id === "phoneNumber") {
      let val = field.value.replace(/[^\d\+]/g, "");
      const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

      if (val.match(phoneRegex)) {
        this.setStatus(field, null, "success");
      } else {
        this.setStatus(field, `Please enter valid phone number`, "error");
        isValid = false;
      }
    }

    return isValid;
  }

  setStatus(field, message, status) {
    const successIcon = field.parentElement.querySelector(".icon-success");
    const errorIcon = field.parentElement.querySelector(".icon-error");
    const errorMessage = field.parentElement.querySelector(".error-message");

    if (status === "success") {
      if (errorIcon) {
        errorIcon.classList.add("hidden");
      }
      if (errorMessage) {
        errorMessage.innerText = "";
      }
      if (successIcon) successIcon.classList.remove("hidden");
      field.parentElement.classList.remove("input-error");
      // field.classList.remove("input-error");
    }

    if (status === "error") {
      if (successIcon) {
        successIcon.classList.add("hidden");
      }
      if (errorIcon) {
        errorIcon.classList.remove("hidden");
      }
      field.parentElement.querySelector(".error-message").innerText = message;
      field.parentElement.classList.add("input-error");
      // field.classList.add("input-error");
    }
  }
}

export default FormValidator;
