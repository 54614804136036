import { getShadowRoot, resetStore } from "./store";
import { resetSteps } from "./StepService";
const initialState = {
  isOpen: false,
  fullScreen: false,
  redirectUri: null,
  successCallback: () => {},
  errorCallback: () => {},
  completeCallback: () => {},
  closeCallback: () => {}
};

let state = initialState;

export const getDialogState = () => state;

export const setRedirectUri = (redirectUri) =>
  (state = { ...state, redirectUri });

export const setFullScreen = (fullScreen) => (state = { ...state, fullScreen });

export const hideShowDialog = (show = true) => {
  var modal = getShadowRoot().querySelector("#identityModal");

  modal.style.display = !!show
    ? "flex"
    : setTimeout(function () {
        modal.style.display = "none";
      }, 300);

  show ? modal.classList.add("show") : modal.classList.remove("show");

  state = {
    ...state,
    isOpen: !!show,
  };

  if (!show) {
    let formTabs = getShadowRoot().querySelectorAll("[data-form-tab]");
    formTabs.forEach((formTab) => {
      formTab.remove();
    });

    resetStore();
    resetSteps();
    state = initialState;
  }
};

export const setErrorCallback = (callback) =>
  (state = { ...state, errorCallback: callback });

export const setSuccessCallback = (callback) =>
  (state = { ...state, successCallback: callback });

export const isFullScreen = () => state.fullScreen;

export const setCloseCallback = (callback) =>
  (state = { ...state, closeCallback: callback });

export const setCompleteCallback = (callback) =>
  (state = { ...state, completeCallback: callback });
