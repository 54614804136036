import { nextPrev } from "../utils/StepService";
import { getShadowRoot } from "../utils/store";
class OtpConfirmation {
  render() {
    const modalBox = getShadowRoot().querySelector("#identity-modal__box");
    if (modalBox.querySelector("#otpConfirmationContent")) return;

    modalBox.insertAdjacentHTML(
      "beforeend",
      `<form class="form-content" id="otpConfirmationContent" data-form-tab>
        <h3 class="identity__text-center">Confirm</h3>
        <div class="identity__row">
            <div class="identity__col-12">
              <div class="identity-alert__error" id="identity-alert__error" style="display:none;">
                <button data-close="close" style="display:none;" type="button">&times;</button>
                <svg class="icon" width="20px" height="20px" aria-hidden="true" role="img"><use href="#svg__alert-error" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__alert-error"></use></svg>
                <div id="identity-alert__message" data-text="text"></div>
              </div>
            </div>
            <div class="identity__col-12">
                <p class="identity__text-center identity__mt0"> Please continue to proceed with One Time Password and quickly finish verification.</p>
                <br/><br/>
            </div>
            <div class="identity__col-12">
                <div class="identity-field">
                <button type="submit" class="identity-btn">Continue
                <span class="loading-icon">
                  <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img">
                    <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                  </svg>
                </span>
                </button>
                </div>
            </div>
        </div>
      </form>`
    );

    this.bindEvents();
  }

  bindEvents() {
    const otpConfirmationForm = getShadowRoot().querySelector(
      "#otpConfirmationContent"
    );

    otpConfirmationForm.addEventListener("submit", (event) => {
      event.preventDefault();
      nextPrev(1);
    });
  }
}

export default OtpConfirmation;
