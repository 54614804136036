import { getDialogState } from "../utils/DialogService";
import { nextPrev, setCurrentStep } from "../utils/StepService";
import { request } from "../utils/axios";
import { getSessionData, getSessionId, getShadowRoot, setSessionData } from "../utils/store";

class MobileVerificationQR {
  interval = null;

  async render() {
    let modalBox = getShadowRoot().querySelector("#identity-modal__box");

    if (modalBox.querySelector("#mobileVerificationQR")) return;

    await this.getQRCode();

    var sessionData = getSessionData();

    modalBox.insertAdjacentHTML(
      "beforeend",
      `<div class="form-content mobile-verification-qr" id="mobileVerificationQR" data-form-tab>
                <div class="identity__row">
                    <div class="identity__col-12 identity__text-center">
                        <h3 class="identity__h3">Mobile Verification</h3>
                    </div>
                    <div class="identity__col-12 identity__text-center">
                        <p>Scan the provided QR Code or use the 'Send Link' button to recieve a link on your mobile phone. This link will allow you to continue the process seamlessly on your mobile device.</p>
                    </div>
                    <div class="identity__col-12 identity__text-center">
                        <img height="275px" width="275px" src="data:image/png;base64, ${sessionData.qrCode}"></img>
                    </div>
                    <div class="identity__col-12 identity__text-center">
                        <p>OR</p>
                    </div>
                    <div class="identity__col-12 identity__text-center">
                        <button class="identity-btn" type="button" id="identity__btnSendMobileLink">SEND LINK</button>
                    </div>
                </div>
            </div>
            `
    );

    this.bindEvents();
  }

  bindEvents() {
    let sendLinkBtn = getShadowRoot().querySelector("#identity__btnSendMobileLink");
    sendLinkBtn.addEventListener("click", async () => {
      clearInterval(this.interval)

      await setCurrentStep("moblieVerificationLink");
      await nextPrev(1);
    });

    this.checkFlowInitiatedFromMobile();
  }

  getQRCode() {
    return request
      .get(`/mobile/qr/${getSessionId()}`)
      .then(({ data }) => {
        setSessionData({ ...data.data });
      })
      .catch(() => {});
  }

  checkFlowInitiatedFromMobile() {
    this.interval = setInterval(() => {
      //clear interval if dialog is closed.
      let dialogState = getDialogState();
      if(!dialogState.isOpen)
        clearInterval(this.interval)

      else{
      return request
        .get(`/Details/${getSessionId()}`)
        .then(async ({ data }) => {
          if (data && data.data && data.data.mobileVerificationType !== null) {
            if (this.interval) clearInterval(this.interval);

            await setSessionData({ ...data.data });
            await nextPrev(1);
          }
        });
      }
    }, 5000);
  }
}

export default MobileVerificationQR;
