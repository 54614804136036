// import { getSessionData } from "../utils/store";
import { closeSession } from "../utils/StepService";
import { getSessionId, getSessionData, getShadowRoot } from "../utils/store";
import { request } from "../utils/axios";
import { setError, getBaseUri, isMobile } from "../utils";
import { getDialogState } from "../utils/DialogService";

class Success {
  render = async () => {
    const result = await this.getFinalStatus();
    const showBtn = this.showContinueButton();
    const showContinueFromWebMessage =this.isMobileVerification();
    let modalBox = getShadowRoot().querySelector("#identity-modal__box");

    if (modalBox.querySelector("#successContent")) return;

    // const sessionData = getSessionData();

    modalBox.insertAdjacentHTML(
      "beforeend",
      `<form class="form-content" data-form-tab id="successContent">
                                <div class="identity__text-center">
                                <svg class="svg" width="74px" height="74px" aria-hidden="true" role="img"><use href="#svg__success" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__success"></use></svg>
                                </div>
                                <h3 class="identity__text-center">Success</h3>
                                <div class="identity__row">
                                <div class="identity__col-12">
                                    <p class="identity__text-center identity__mt0">${ showContinueFromWebMessage ? "You have completed verification successfully. You may now close this page and continue from website." :  `Awesome! ${
                                      result?.personalInfo?.firstName || ""
                                    } ${
        result?.personalInfo?.lastName || ""
      } was successfully verified. ${showBtn ? 'Please "Continue" to move forward.' : ''}</p>`}
                                </div>
                                <div class="identity__col-6">
                                  <div class="identity-field__data">
                                    <label>Account</label>
                                      ${result.accountName}
                                  </div>
                                </div>
                                <div class="identity__col-6">
                                  <!--<div class="identity-field__data">
                                    <label>RCN</label>
                                    000000000
                                  </div>-->
                                </div>
                                <div class="identity__col-6">
                                    <div class="identity-field__data">
                                    <label>Name</label>
                                    ${result?.personalInfo?.firstName || ""} ${
        result?.personalInfo?.lastName || ""
      }
                                    </div>
                                </div>
                                <div class="identity__col-6">
                                    <div class="identity-field__data">
                                    <label>DOB</label>
                                    ${result?.personalInfo?.dob}
                                    </div>
                                </div>
                                  ${showBtn ? `<div class="identity__col-12">
                                    <div class="identity-field">
                                      <button class="identity-btn" type="submit">Continue</button>
                                    </div>
                                  </div>` : ""}
                                </div>
                            </form>`
    );

    this.bindEvents();
  };

  getFinalStatus = () => {
    const sessionData = getSessionData();
    if (sessionData?.showSupportVerificationError === false && sessionData?.personalInfo) {
      let result = sessionData
      if (result?.personalInfo?.dob) {
        result.personalInfo.dob = result.personalInfo.dob.replace(
          /^(\d{4})[-\/](\d{1,2})[-\/](\d{1,2})$/,
          '$2-$3-$1'
        )
      }
      return result
    }
    return request
      .get(`/FinalStatus/${getSessionId()}`)
      .then(({ data }) => {
        let result = data.data
        if (result?.personalInfo?.dob) {
          result.personalInfo.dob = result.personalInfo.dob.replace(
            /^(\d{4})[-\/](\d{1,2})[-\/](\d{1,2})$/,
            '$2-$3-$1'
          )
        }
        return result;
      })
      .catch(({ response }) => {
        setError(response);
      });
  };

  formatDate(inputDate) {
    if (!inputDate) return "";
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
      );
    }
  }

  bindEvents() {
    const successForm = getShadowRoot().querySelector("#successContent");

    successForm.addEventListener("submit", (event) => {
      event.preventDefault();
      closeSession();
    });
  }

  showContinueButton() {
    let show = true,
    dialogState = getDialogState(),
    Uri1 = getBaseUri(dialogState.redirectUri),
    Uri2 = window.location.host;

    if (dialogState.redirectUri && Uri1 && Uri1 === Uri2) show = false;
    return show && !this.isMobileVerification();
  }

  isMobileVerification(){
    let sessionData = getSessionData();
    return isMobile() && sessionData?.mobileVerificationType !== null && sessionData?.mobileVerificationType !== undefined;
  }
}

export default Success;
