import { getSessionId, getSessionData, setSessionData, getShadowRoot } from "../utils/store";
import { request } from "../utils/axios";
import { nextPrev } from "../utils/StepService";
import { setError, uuidv4 } from "../utils";
import { setLoading, formatPhoneNumber } from "../utils";

class SendOtp {
  constructor() {
    this.selected = null;
  }

  render = async () => {
    const options = this.setOtpOptions(),
      modalBox = getShadowRoot().querySelector("#identity-modal__box"),
      sessionData = getSessionData();
    this.formId = `sendOtpContent-${uuidv4()}`;
    const sendOtpBtn = `<div class="identity-field last">
      <button id="btn-continue" type="submit" class="identity-btn btn-continue">Send OTP
        <span class="loading-icon">
          <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img">
            <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
          </svg>
        </span>
      </button>
    </div>`,
      tryAnotherWayLink = `<div class="identity__col-12">
      <p class="identity__text-center">
        <a class="identity__m0" id="tryAnotherWay">Try another way ?</a>
      </p>
    </div>`,
      skipBtn = `<div class="identity-field">
      <button type="button" class="identity-btn identity-btn__secondary skip-loading" id="btnOptionSkip">Try another way ?
        <span class="loading-icon">
          <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img">
            <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
          </svg>
        </span>
      </button>
    </div>`;

    // if (modalBox.querySelector("#sendOtpContent")) return;

    modalBox.insertAdjacentHTML(
      "beforeend",
      `<form class="form-content send-otp-form" method="post" id="${
        this.formId
      }" data-form-tab>
        <h3>Finish Age Verification</h3>
        <div class="identity__scroll_inner">
          <div class="identity__row">
            <div class="identity__col-12">
                <div class="identity-alert__error" id="identity-alert__error" style="display:none;">
                  <button data-close="close" style="display:none;" type="button">&times;</button>
                  <svg class="icon" width="20px" height="20px" aria-hidden="true" role="img"><use href="#svg__alert-error" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__alert-error"></use></svg>
                  <div data-text="text" id="identity-alert__message"></div>
                </div>
            </div>
            <div class="identity__col-12">
              ${options || ""}
              <div class="identity__inner-loader" style="display:none;">
                <svg class="svg" width="100px" height="100px" aria-hidden="true" role="img">
                  <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="identity__row">
        ${
          sessionData?.isVerified === true
            ? `<div class="identity__col-12">${sendOtpBtn}</div>${tryAnotherWayLink}`
            : `${
                sessionData?.skippable === true
                  ? `<div class="identity__col-7">${skipBtn}</div><div class="identity__col-5">${sendOtpBtn}</div>`
                  : `<div class="identity__col-12">${sendOtpBtn}</div>`
              }`
        }
        </div>
      </form>`
    );

    this.bindEvents();
  };

  decodeOption(str) {
    let decodeStr = atob(str);
    const [option, id] = decodeStr.split(":");
    return { [option]: id };
  }

  setOtpOptions() {
    const sessionData = getSessionData();
    let emails =
        sessionData.ownershipVerificationDetails?.emails ||
        sessionData.emails ||
        [],
      phones =
        sessionData.ownershipVerificationDetails?.phones ||
        sessionData.phones ||
        [],
      result;

    if (emails.length || phones.length) {
      result = `<ul class="identity__select-list">`;
      if (emails.length) {
        for (const emailOption of emails) {
          result += `<li value="${btoa(
            "email:" + emailOption.id
          )}" class="option">Email: ${emailOption.email}</li>`;
        }
      }

      if (emails.length && phones.length) {
        result += `<li class="divider"><div class="identity__or"></div></li>`;
      }

      if (phones.length) {
        for (const phoneOption of phones) {
          result += `<li value="${btoa(
            "phone:" + phoneOption.id
          )}" class="option">Phone: ${formatPhoneNumber(
            phoneOption.phone
          )}</li>`;
        }
      }

      result += `</ul>`;
    }
    return result;
  }

  selectOption(event) {
    if (!event.target.classList.contains("selected")) {
      const sendOtpForm = getShadowRoot().querySelector(`#${this.formId}`);
      sendOtpForm
        .querySelector("ul.identity__select-list li.selected")
        .classList.remove("selected");
      event.target.classList.add("selected");
      this.selected = this.decodeOption(event.target.getAttribute("value"));
    }
  }

  buttonLoader(buttonElement, loading) {
    // if (!buttonElement.classList.contains("skip-loading")) {
    if (loading) buttonElement.classList.add("identity-btn-loading");
    else buttonElement.classList.remove("identity-btn-loading");
    // }
  }

  async tryAnotherWayHandler() {
    const sendOtpForm = getShadowRoot().querySelector(`#${this.formId}`);
    const numberList = sendOtpForm.querySelector(".identity__select-list"),
      loader = sendOtpForm.querySelector(".identity__inner-loader");

    let numberListOffsetHeight = 60;
    if (numberList) {
      numberListOffsetHeight = numberList.getBoundingClientRect().height;
      numberList.style.display = "none";
    }
    loader.style.display = "flex";
    loader.style.height = numberListOffsetHeight + "px";

    await request
      .post(`/auth/tryanother/${getSessionId()}`)
      .then(({ data }) => {
        setSessionData({ ...data.data });
        nextPrev(1);
      })
      .catch(({ response }) => {
        setError(response);
      });

    loader.style.display = "none";
    if (numberList) numberList.style.display = "block";

    // selected First option
    /* let options = sendOtpForm.querySelectorAll(
      "ul.identity__select-list li.option"
    );
    if (options && options.length) {
      options.forEach((option, index) => {
        if (index === 0) {
          option.classList.add("selected");
          this.selected = this.decodeOption(option.getAttribute("value"));
        }
        option.addEventListener("click", this.selectOption.bind(this));
      });
    } */
  }

  bindEvents() {
    const sendOtpForm = getShadowRoot().querySelector(`#${this.formId}`),
      btnOptionSkip = sendOtpForm.querySelector("#btnOptionSkip"),
      tryAnotherWay = sendOtpForm.querySelector("#tryAnotherWay");

    sendOtpForm.addEventListener("submit", (event) => {
      event.preventDefault();

      if (this.selected) {
        setLoading(true);

        let requestObj = {};
        this.selected.email
          ? (requestObj.emailId = this.selected.email)
          : (requestObj.phoneId = this.selected.phone);

        request
          .post(`/otp/${getSessionId()}`, requestObj)
          .then(({ data }) => {
            setSessionData({ ...data.data, nextVerificationStep: "verifyOtp" });
            setSessionData({ resendOtpObj: requestObj });
            nextPrev(1);
          })
          .catch(({ response }) => {
            setError(response);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });

    // selected First option
    let options = sendOtpForm.querySelectorAll(
      "ul.identity__select-list li.option"
    );

    if (options && options.length) {
      options.forEach((option, index) => {
        if (index === 0) {
          option.classList.add("selected");
          this.selected = this.decodeOption(option.getAttribute("value"));
        }
        option.addEventListener("click", this.selectOption.bind(this));
      });
    }

    if (btnOptionSkip)
      btnOptionSkip.addEventListener("click", () => {
        this.buttonLoader(btnOptionSkip, true);
        request
          .post(`/otp/skip/${getSessionId()}`)
          .then(({ data }) => {
            setSessionData({ ...data.data });
            nextPrev(1);
          })
          .catch(({ response }) => {
            setError(response);
          })
          .finally(() => {
            this.buttonLoader(btnOptionSkip, false);
          });
      });

    if (tryAnotherWay)
      tryAnotherWay.addEventListener(
        "click",
        this.tryAnotherWayHandler.bind(this)
      );
  }
}

export default SendOtp;
