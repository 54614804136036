import { setError, uuidv4, setLoading } from "../utils";
import { getSessionData, getSessionId, getShadowRoot, setSessionData } from "../utils/store";
import { request } from "../utils/axios";
import { getDialogState } from "../utils/DialogService";

class QRForReVerification {
  getCurrentTimeStamp = () => {
    return Math.floor(Date.now() / 1000);
  };

  shadow = null;
  timerInterval = null;
  currentTimeStamp = this.getCurrentTimeStamp();
  constructor() {}
  render = async () => {
    await this.generateQRCode();

    let sessionData = getSessionData();

    this.formId = `qrForReveirifcation-${uuidv4()}`;
    let modalBox = getShadowRoot().querySelector("#identity-modal__box");

    modalBox.insertAdjacentHTML(
      "beforeend",
      `<div class="form-content qr-re-verification" method="post" id="${this.formId}" data-form-tab>
                <div class="identity__row">
                    <div class="identity__col-12">
                        <div class="identity-alert__error" id="identity-alert__error" style="display:none;">
                            <button data-close="close" style="display:none;" type="button">&times;</button>
                            <svg class="icon" width="20px" height="20px" aria-hidden="true" role="img"><use href="#svg__alert-error" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__alert-error"></use></svg>
                            <div data-text="text" id="identity-alert__message"></div>
                        </div>
                    </div>
                    <div class="identity__col-12">
                        <div class = "identity-field">
                            <div class="identity__por identity__r-6 identity__text-center">
                            <h3 class="identity__h3">QR Code</h3>
                                <img src = "data:image/png;base64,${sessionData.qrCodeData.qrCode}"  style="width:275px;height:275px;" id="identity_qr_img"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="identity__row" id="identity_qr_countdown">
                    <div class="identity__col-12 identity__text-center">
                         <p class="identity__text-center" >Expires in <span id="identity_countdown_text">01:59</span></p>
                    </div>
                </div>
                <div class="identity__row" id="identity_qr_reload" style="display:none;">
                    <div class="identity__col-12 identity__text-center">
                        <button type="submit" id="btn-continue" class="identity-btn btn-continue identity-btn-qr-reload">
                            Reload
                            <span class="loading-icon">
                                <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img">
                                    <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
             </div>`
    );

    this.bindEvents();
  };

  generateQRCode = async () => {
    return request
      .get(`re/qr/${getSessionId()}`)
      .then(({ data }) => {
        if (data && data.data) {
          setSessionData({
            qrCodeData: data.data,
          });
        }
        return data;
      })
      .catch(({ response }) => {
        setError(response);
      });
  };

  bindEvents = () => {
    this.showTimer();

    let reloadButton = getShadowRoot().querySelector(".identity-btn-qr-reload");
    reloadButton.addEventListener("click", this.reloadQR);
  };

  reloadQR = () => {
    setLoading(true);
    this.generateQRCode().then(() => {
      setLoading(false);
      let sessionData = getSessionData();
      this.currentTimeStamp = this.getCurrentTimeStamp();

      this.showTimer();

      let img = getShadowRoot().querySelector("#identity_qr_img");

      img.src = "data:image/png;base64," + sessionData.qrCodeData.qrCode;
    });
  };

  showTimer = () => {
    let qrTime = getShadowRoot().querySelector("#identity_qr_countdown");
    qrTime.style.display = "block";

    let qrReload = getShadowRoot().querySelector("#identity_qr_reload");
    qrReload.style.display = "none";

    this.timerCountDown();
  };

  showReload = () => {
    let qrTime = getShadowRoot().querySelector("#identity_qr_countdown");
    qrTime.style.display = "none";

    let qrReload = getShadowRoot().querySelector("#identity_qr_reload");
    qrReload.style.display = "block";
  };

  timerCountDown = () => {
    let sessionData = getSessionData();

    this.timerInterval = setInterval(() => {
      let dialogState = getDialogState();

      if (!dialogState.isOpen) {
        clearInterval(this.timerInterval);
      } else {
        this.currentTimeStamp = this.getCurrentTimeStamp();

        if (sessionData.qrCodeData.expiresAt - this.currentTimeStamp <= 0) {
          clearInterval(this.timerInterval);
          this.showReload();
        } else {
          this.setTime();
        }
      }
    }, 1000);
  };

  setTime = () => {
    let countdown = getShadowRoot().querySelector("#identity_countdown_text");

    countdown.innerText = this.getFormattedRemainingTime();
  };

  getFormattedRemainingTime = () => {
    let sessionData = getSessionData();

    let remainingSeconds =
      sessionData.qrCodeData.expiresAt - this.currentTimeStamp;

    let minutes = Math.floor(remainingSeconds / 60);
    let finalRemainingSeconds = remainingSeconds - minutes * 60;

    return remainingSeconds > 0
      ? (minutes < 10 ? "0" : "") +
          minutes +
          ":" +
          (finalRemainingSeconds < 10 ? "0" : "") +
          finalRemainingSeconds
      : "00:00";
  };
}

export default QRForReVerification;
