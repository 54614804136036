import { request } from "../utils/axios";
import {
  getSessionId,
  setSessionData,
  getSessionData,
  getShadowRoot,
} from "../utils/store";
import { nextPrev } from "../utils/StepService";
import {
  setLoading,
  setError,
  sleep,
  getErrorHtmlFromResponse,
  uuidv4,
} from "../utils/index";

import { sampleBase64 } from "./sampleBase64";
import AwsLiveness from "./AwsLiveness";
import React from "react";
import ReactDOM from "react-dom/client";
import { cScriptLoader } from "../utils/ScriptLoader";

let antiSpoofingType = "TWO_STAGES_WITH_RANDOM_ACTIONS",
  precisionLevel = "fast",
  raNum = 2,
  raNodEnable = true,
  raSmileEnable = true;

// const defaultErrorMSG = "Your selfie verifiation got failed, please try again.";

class SelfiVerification {
  constructor() {
    this.verified = false;
    this.loading = false;
    this.loadingFaceMe = false;

    this.status = "FRONT";
    //we store user's frontFace for enroll and sign in purpose
    this.frontFace = null;
    //we also store user's latest face for UI purpose
    this.latestFace = null;
    //we also store user's strict face for UI purpose
    this.strictFace = null;
    this.selectedCamera = "";
    this.cameras = [];
    this.secondStageParameter = {
      antiSpoofingType,
      precisionLevel,
      raNum,
      raNodEnable,
      raSmileEnable,
    };

    this.error = null;
    this.livenessRef = React.createRef();
    this.formId = null;
  }

  render = async () => {
    const sessionData = getSessionData();
    if (!sessionData.livenessSessionId) await this.generateLivenessSession();
    let modalBox = getShadowRoot().querySelector("#identity-modal__box");
    this.formId = `selfieVerificationContent-${uuidv4()}`;

    const path = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? ":" + window.location.port : ""
    }`;
    const { ORIGIN_URL, LOCAL_RUN } = process.env;
    const facemeSdkOrigin = LOCAL_RUN ? path : ORIGIN_URL;

    if (facemeSdkOrigin) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = `${facemeSdkOrigin}/aws-liveness-styles.css`;
     modalBox.appendChild(link);
    }

    // if (modalBox.querySelector("#selfieVerificationContent")) return;

    modalBox.insertAdjacentHTML(
      "beforeend",
      `<form class="form-content selfi-verification" id="${
        this.formId
      }" data-form-tab>
                                <h3>Live Selfie Verification</h3>
                                <div class="identity__row">
                                <div class="identity__col-12">
                                  <div class="identity-alert__error" id="identity-alert__error" style="display:none;">
                                    <button data-close="close" style="display:none;" type="button">&times;</button>
                                    <svg class="icon" width="20px" height="20px" aria-hidden="true" role="img"><use href="#svg__alert-error" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__alert-error"></use></svg>
                                    <div id="identity-alert__message" data-text="text"></div>
                                  </div>
                                </div>
                                <div class="identity__col-12" id="face">
                                      <div class="sdk-area" id="sdk-area">
                                        <!--<div class="identity__outline-box identity__por identity__r-6" id="sdk_block" style="height: 100%; width: 100%">
                                            <span class="identity__poa identity__left identity__right identity__bottom identity__text-center identity__label">Tap to start live selfie verification</span>
                                            <div class="identity__outline-inner identity__height-2x">
                                              <svg id="faceMeSdkCameraIcon" class="svg" width="60px" height="60px" aria-hidden="true" role="img"><use href="#svg__camera" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__camera"></use></svg>
                                            </div>
                                        </div>-->
                                      </div>
                                      <div class="face-me-sdk-loading" id="faceMeSdkLoading" style="display:none;">
                                        <svg class="svg" width="100px" height="100px" aria-hidden="true" role="img">
                                          <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                                        </svg>
                                      </div>
                                      <div class="face-loading" id="faceLoading" style="display:none;">
                                        <svg class="svg" width="60px" height="60px" aria-hidden="true" role="img">
                                          <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                                        </svg>
                                        <div>Loading...</div>
                                      </div>
                                      <div class="face-verified" id="faceVerified" style="display:none;">
                                        <div class="face-verified-title"><span>Your Live Verification is </span>Successful!</div>  
                                        <svg class="svg" width="64px" height="64px" aria-hidden="true" role="img"><use href="#svg__success" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__success"></use></svg>    
                                      </div>
                                      <div class="face-error" id="faceError" style="display:none;">
                                          <svg class="svg" width="64px" height="64px" aria-hidden="true" role="img"><use href="#svg__error" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__error"></use></svg>
                                          <h2>Sorry!</h2>
                                          <div id="face-message"></div>
                                          <button class="identity-btn skip-loading" type="button" id="retrySelfie">Retry
                                            <span class="loading-icon">
                                              <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img">
                                                <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                                              </svg>
                                            </span>
                                          </button>
                                      </div>
                                      ${
                                        sessionData?.showRetry === true
                                          ? `<div class="identity__col-12">
                                        <p class="identity__text-center identity__mb0">
                                          <a class="identity__m0" id="tryAnotherWay">Try another way ?</a>
                                        </p>
                                      </div>`
                                          : ""
                                      }
                                </div>
                                <div class="identity__col-12 selfi-footer">
                                    <div class="identity__row column-reverse-xs">
                                    <div class="identity__col-6" style="display:none;">
                                        <div class="identity-field">
                                            <button id="btn-continue" disabled type="submit" class="identity-btn btn-continue">Continue
                                              <span class="loading-icon">
                                                <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img">
                                                  <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                                                </svg>
                                              </span>
                                            </button>
                                        </div>
                                    </div>
                                    </div>                                                
                                </div>
                                </div>
                            </form>`
    );

    this.bindEvents();
  };

  async generateLivenessSession() {
    try {
      let response = await request.post(`/Liveness/session/${getSessionId()}`);
      if (response?.data?.data?.livenessSessionId)
        setSessionData({
          livenessSessionId: response.data.data.livenessSessionId,
        });
      return response?.data?.data?.livenessSessionId || "";
    } catch (error) {
      this.toggleCameraClass(false);
      setLoading(false, false);
    }
  }

  async tryAnotherWayHandler() {
    const selfieVerification = getShadowRoot().querySelector(`#${this.formId}`);
    let sdkBlock = selfieVerification.querySelector("#sdk_block");
    if (sdkBlock) sdkBlock.style.display = "none";

    this.setStatus("faceLoading");

    await request
      .post(`/auth/tryanother/${getSessionId()}`)
      .then(({ data }) => {
        setSessionData({ ...data.data });
        nextPrev(1);
      })
      .catch(({ response }) => {
        setError(response);
      })
      .finally(() => {
        this.resetStatus();
        sdkBlock.style.display = "block";
      });
  }

  bindEvents() {
    const selfieVerification = getShadowRoot().querySelector(`#${this.formId}`);
    // Inject AwsLiveness component inside sdk-area id
    const root = ReactDOM.createRoot(
      selfieVerification.querySelector("#sdk-area")
    );
    if (root)
      root.render(
        <AwsLiveness
          ref={this.livenessRef}
          initializeLiveness={this.initializeLiveness.bind(this)}
          successCallback={this.successCallback.bind(this)}
          errorCallback={this.errorCallback.bind(this)}
          toggleCameraClass={this.toggleCameraClass.bind(this)}
          formId={this.formId}
        />
      );

    /* sdkBlock.addEventListener("click", () => {
      // this.setStatus("faceLoading");
      this.initializeFaceMe();
    }); */

    const tryAnotherWay = selfieVerification.querySelector("#tryAnotherWay");

    selfieVerification.addEventListener("submit", (event) => {
      event.preventDefault();
      if (!this.verified && !this.loading)
        setError("Please complete Selfie Verification.", true);
    });

    const retrySelfie = selfieVerification.querySelector("#retrySelfie");

    if (retrySelfie)
      retrySelfie.addEventListener("click", async (event) => {
        try {
          this.buttonLoader(retrySelfie, true);
          let sessionId = await this.generateLivenessSession();
          if (sessionId) this.initializeLiveness();
        } catch (err) {
          console.log("generateLivenessSession error", err);
        } finally {
          this.buttonLoader(retrySelfie, false);
        }
      });

    if (tryAnotherWay)
      tryAnotherWay.addEventListener(
        "click",
        this.tryAnotherWayHandler.bind(this)
      );
  }

  buttonLoader(buttonElement, loading) {
    // if (!buttonElement.classList.contains("skip-loading")) {
    if (loading) buttonElement.classList.add("identity-btn-loading");
    else buttonElement.classList.remove("identity-btn-loading");
    // }
  }

  async initializeFaceMe() {
    if (this.loadingFaceMe) return;
    setError(false);
    this.disableButtons(true);
    this.loadingFaceMe = true;
    this.toggleCameraClass(true);
    await sleep(200);
    this.setStatus("faceMeSdkLoading");
  }

  async initializeLiveness() {
    this.resetStatus();
    this.disableButtons(false);
    this.toggleCameraClass(true);

    // hide tryAnotherWay link
    const sessionData = getSessionData();
    if (sessionData?.showRetry) {
      this.showHideTryAnotherLink(false);
    }

    if (this.livenessRef) {
      this.livenessRef.current.startLiveness();
    }
  }

  successCallback() {
    const selfieVerification = getShadowRoot().querySelector(`#${this.formId}`);
    let sdkBlock = selfieVerification.querySelector("#sdk_block");
    if (sdkBlock) sdkBlock.style.display = "none";

    this.loading = true;
    this.setStatus("faceLoading");

    let formData = new FormData();
    let params = new URLSearchParams(window.location.search),
      internalTest = params.get("internalTest");
    if (internalTest) {
      formData.append("ImageBase64", sampleBase64);
    }

    setLoading(true);

    const sessionData = getSessionData(),
      URL =
        sessionData?.isVerified === true
          ? `/auth/Liveness/${getSessionId()}`
          : `/Liveness/${getSessionId()}`;
    request
      .post(URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        if (data.succeeded && data.data?.nextVerificationStep) {
          setSessionData(data.data);
          this.toggleCameraClass(false);

          if (data.data?.nextVerificationStep != "Failure") {
            this.verified = true;
            this.setStatus("faceVerified");
          }
          nextPrev(1);
        }
      })
      .catch(({ response }) => {
        if (
          response?.data &&
          response.data?.errors &&
          response.data.errors?.FIRST_NAME_MISMATCH
        ) {
          this.toggleCameraClass(false);
          setLoading(false, false);
          setSessionData({
            showIdVerification: true,
            idVerificationMessage: response.data.errors.FIRST_NAME_MISMATCH[0],
          });
          nextPrev(1);
        } else if (
          response?.data &&
          response.data?.errors &&
          response?.data?.errors?.REDIRECT_BACK
        ) {
          this.toggleCameraClass(false);
          setLoading(false, false);
          setSessionData({
            showIdVerification: false,
            redirectBack: true,
          });
          nextPrev(1);
        } else {
          this.setStatus("faceError", getErrorHtmlFromResponse(response));
          this.toggleCameraClass(false);
          setLoading(false, false);
          if (this.livenessRef) {
            this.livenessRef.current.setLivenessError(true);
          }
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  errorCallback(errorMsg) {
    this.resetStatus();
    this.toggleCameraClass(false);
    this.setStatus("faceError", errorMsg);

    // show tryAnotherWay link
    const sessionData = getSessionData();
    if (sessionData?.showRetry) {
      this.showHideTryAnotherLink(true);
    }
  }

  toggleCameraClass(isOpen) {
    const identityModalContainer = getShadowRoot().querySelector(
      "#identityModalContainer"
    );

    if (isOpen) identityModalContainer.classList.add("camera-open");
    else identityModalContainer.classList.remove("camera-open");
  }

  showHideTryAnotherLink(isShow) {
    const selfieVerification = getShadowRoot().querySelector(`#${this.formId}`),
      tryAnotherWay = selfieVerification.querySelector("#tryAnotherWay");
    if (tryAnotherWay) tryAnotherWay.style.display = isShow ? "block" : "none";
  }

  setStatus(id = null, message = "") {
    const selfieVerification = getShadowRoot().querySelector(`#${this.formId}`);
    const options = [
      "faceLoading",
      "faceVerified",
      "faceError",
      "faceMeSdkLoading",
      "faceMeSdkCameraIcon",
    ];

    options.forEach((option) => {
      let element = selfieVerification.querySelector(`#${option}`);

      if (element) {
        element.style.display = id && option === id ? "flex" : "none";

        if (message) {
          let messgeBlock = element.querySelector(`#face-message`);

          if (messgeBlock) messgeBlock.innerHTML = message;
        }
      }
    });
  }

  resetStatus() {
    const selfieVerification = getShadowRoot().querySelector(`#${this.formId}`);
    const options = ["faceLoading", "faceVerified", "faceError"];

    options.forEach((option) => {
      let element = selfieVerification.querySelector(`#${option}`);
      if (element) element.style.display = "none";
    });
  }

  disableButtons(disable) {
    const selfieVerification = getShadowRoot().querySelector(`#${this.formId}`);
    let buttons = selfieVerification.querySelectorAll(
      ".form-content.active button"
    );

    buttons.forEach((button) => {
      button.disabled = disable;
    });
  }
}

export default SelfiVerification;
