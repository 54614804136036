import FormValidator from "./FormValidator";
import { request } from "../utils/axios";
import { getSessionId, setSessionData, getSessionData, getShadowRoot } from "../utils/store";
import { nextPrev, closeSession } from "../utils/StepService";
import { setLoading, setError } from "../utils";
import { getDialogState } from "../utils/DialogService";

class OwnershipName {
  validator = null;
  personalInfoObj = {};
  allowEdit = false;
  timestamp = Date.now()

  fillInputs = async () => {
    if (!this.personalInfoObj?.firstName) return;
    const personalInfoForm = getShadowRoot().querySelector(`#ownershipNameContent`);
    if (personalInfoForm) {
      personalInfoForm.querySelector(`#firstName-${this.timestamp}`).value =
        this.personalInfoObj?.firstName || "";
      personalInfoForm.querySelector(`#lastName-${this.timestamp}`).value =
        this.personalInfoObj?.lastName || "";
    }
  };

  render = async () => {
    const sessionData = getSessionData();
    if (!sessionData?.preFillInformation) await this.getSessionDetail();
    let modalBox = getShadowRoot().querySelector("#identity-modal__box");
    if (modalBox.querySelector("#ownershipNameContent")) return;

    modalBox.insertAdjacentHTML(
      "beforeend",
      `<form class="form-content" id="ownershipNameContent" data-form-tab>
          <h3 class="title">Ownership Verification</h3>
          <div class="identity__row">
            <div class="identity__col-12">
              <div class="identity-alert__error" id="identity-alert__error" style="display:none;">
                <button data-close="close" style="display:none;" type="button">&times;</button>
                <svg class="icon" width="20px" height="20px" aria-hidden="true" role="img"><use href="#svg__alert-error" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__alert-error"></use></svg>
                <div id="identity-alert__message" data-text="text"></div>
              </div>
            </div>
            <div class="identity__col-6">
              <div class="identity-field floating-field">
                  <input name="firstName" id="firstName-${this.timestamp}" type="text" class="identity-input input" placeholder="none" maxlength="50" onkeypress="return /^[a-zA-Z]*$/i.test(event.key)" />
                  <label for="firstName-${this.timestamp}" class="identity-floating-label">First Name*</label>
                  <svg class="icon icon-error " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                    </svg>
                  <span class="error-message"></span>
              </div>
            </div>
            <div class="identity__col-6">
              <div class="identity-field floating-field">
                  <input name="lastName" id="lastName-${this.timestamp}" type="text" placeholder="none" class="identity-input input" maxlength="50" onkeypress="return /^[a-zA-Z]*$/i.test(event.key)" />
                    <label for="lastName-${this.timestamp}" class="identity-floating-label">Last Name*</label>
                  <svg class="icon icon-error " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                      </svg>
                  <span class="error-message"></span>
              </div>
            </div>
            <div class="identity__col-12">
              <div class="identity-field">
                <button id="btn-continue" type="submit" class="identity-btn btn-continue">Continue                                            
                  <span class="loading-icon">
                    <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img">
                      <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
      </form>`
    );

    this.bindEvents();
  };

  bindEvents() {
    const personalInfoForm = getShadowRoot().querySelector(`#ownershipNameContent`);

    personalInfoForm.addEventListener("submit", (event) => {
      event.preventDefault();

      const fields = {
        [`firstName-${this.timestamp}`]: {
          required: true,
        },
        [`lastName-${this.timestamp}`]: {
          required: true,
        },
      };

      this.validator = new FormValidator(event.target, fields);

      let validData = this.validator.validate();
      if (validData.isValid) {
        setLoading(true);

        request
          .post(`/ownership/name/${getSessionId()}`, {
            ...validData.data,
          })
          .then(({ data }) => {
            setSessionData({ ...data.data });
            nextPrev(1);
          })
          .catch(({ response }) => {
            if (
              response?.data &&
              response.data?.errors &&
              response?.data?.errors?.REDIRECT_BACK
            ) {
              setSessionData({
                redirectBack: true,
              });
              nextPrev(1);
            } else setError(response);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });

    const sessionData = getSessionData();
    if (
      sessionData?.preFillInformation &&
      Object.values(sessionData.preFillInformation).length
    ) {
      this.personalInfoObj = sessionData?.preFillInformation;
      this.fillInputs();
    }
  }

  getSessionDetail = async () => {
    return await request
      .get(`/Details/${getSessionId()}`)
      .then(({ data }) => {
        if (data.data) {
          setSessionData({ ...data.data });
        }
      })
      .catch(() => {
        var dialogState = getDialogState();
        closeSession();
        dialogState.errorCallback();
      });
  };
}

export default OwnershipName;
