import { getSessionId, getSessionData, setSessionData, getShadowRoot } from "../utils/store";
import { request } from "../utils/axios";
import { nextPrev } from "../utils/StepService";
import { setLoading, setError, uuidv4 } from "../utils";

class VerifyOtp {
  constructor() {
    this.interval = null;
    this.timer = 30;
  }

  render() {
    let modalBox = getShadowRoot().querySelector("#identity-modal__box");

    this.formId = `verifyOtpContent-${uuidv4()}`;

    // if (modalBox.querySelector("#verifyOtpContent")) return;

    modalBox.insertAdjacentHTML(
      "beforeend",
      `<form class="form-content verify-otp" method="post" id="${this.formId}" data-form-tab>
                                <h3>Verify OTP</h3>
                                <div class="identity__row">
                                <div class="identity__col-12">
                                  <div class="identity-alert__error" id="identity-alert__error" style="display:none;">
                                    <button data-close="close" style="display:none;" type="button">&times;</button>
                                    <svg class="icon" width="20px" height="20px" aria-hidden="true" role="img"><use href="#svg__alert-error" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__alert-error"></use></svg>
                                    <div id="identity-alert__message" data-text="text"></div>
                                  </div>
                                </div>
                                <div class="identity__col-12">
                                    <div class="identity__row identity__otp">
                                    <div class="identity__col">
                                        <div class="identity-field">
                                        <input type="tel" onkeypress="return /[0-9]/i.test(event.key)" maxlength="1" class="identity-input identity-otp" />
                                        </div>
                                    </div>
                                    <div class="identity__col">
                                        <div class="identity-field">
                                        <input type="tel" onkeypress="return /[0-9]/i.test(event.key)" maxlength="1" class="identity-input identity-otp" />
                                        </div>
                                    </div>
                                    <div class="identity__col">
                                        <div class="identity-field">
                                        <input type="tel" onkeypress="return /[0-9]/i.test(event.key)" maxlength="1" class="identity-input identity-otp" />
                                        </div>
                                    </div>
                                    <div class="identity__col">
                                        <div class="identity-field">
                                        <input type="tel" onkeypress="return /[0-9]/i.test(event.key)" maxlength="1" class="identity-input identity-otp" />
                                        </div>
                                    </div>
                                    <div class="identity__col">
                                        <div class="identity-field">
                                        <input type="tel" onkeypress="return /[0-9]/i.test(event.key)" maxlength="1" class="identity-input identity-otp" />
                                        </div>
                                    </div>
                                    <div class="identity__col">
                                        <div class="identity-field">
                                        <input type="tel" onkeypress="return /[0-9]/i.test(event.key)" maxlength="1" class="identity-input identity-otp" />
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="identity__col-12">
                                    <div class="identity-field">
                                        <button id="btn-continue" type="submit" class="identity-btn btn-continue">Verify Now
                                            <span class="loading-icon">
                                                <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img">
                                                <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                    <div class="identity-field last resendotp">
                                        <p class="identity__m0" id="resendOtpLbl">If you do not receive OTP in the next  <span class="identity__text-primary otp-timer">30 Seconds</span>
                                        , please hit Resend!
                                        </p>
                                        <button id="resendOtpLink" type="button" class="identity-btn identity-btn__secondary" style="display:none;">Resend OTP
                                        </button>
                                    </div>
                                </div>
                                </div>
                            </form>`
    );

    this.bindEvents();
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    return (
      (charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105)
    );
  }

  resendOtp() {
    const self = this;
    const sessionData = getSessionData();
    let isVerified = sessionData?.profileDetails?.isVerified ? true : false;
    const URL = isVerified
      ? `/auth/otp/${getSessionId()}`
      : `/otp/${getSessionId()}`;
    request
      .post(URL, sessionData.resendOtpObj)
      .then(({ data }) => {
        setSessionData(data.data);
        self.countDownTimer();
      })
      .catch(({ response }) => {
        setError(response);
      })
      .finally(() => { });
  }

  resetTimer() {
    if (this.interval) clearInterval(this.interval);
    this.timer = 30;
  }

  countDownTimer() {
    const self = this;
    let verifyOtp = getShadowRoot().querySelector(`#${this.formId}`);
    let counterElement = verifyOtp.querySelector("span.otp-timer");
    counterElement.innerText = self.timer + " Seconds";
    verifyOtp.querySelector("#resendOtpLink").style.display = "none";
    verifyOtp.querySelector("#resendOtpLbl").style.display = "block";
    self.interval = setInterval(function () {
      self.timer -= 1;
      if (self.timer > 0) {
        counterElement.innerText = self.timer + " Seconds";
      } else {
        verifyOtp.querySelector("#resendOtpLink").style.display = "block";
        verifyOtp.querySelector("#resendOtpLbl").style.display = "none";
      }
      if (self.timer === 0) {
        self.resetTimer();
      }
    }, 1000);
  }

  handleFocus(index, event) {
    let verifyOtp = getShadowRoot().querySelector(`#${this.formId}`);
    if (event.keyCode === 8 || event.keyCode === 46) {
      let element = verifyOtp.querySelectorAll("input.identity-otp")[index - 1];
      if (element) {
        element.focus();
        element.select();
      }
    }
    if (
      event.target.value &&
      !isNaN(event.target.value) &&
      this.isNumberKey(event)
    ) {
      let element = verifyOtp.querySelectorAll("input.identity-otp")[index + 1];
      if (element) {
        element.focus();
        element.select();
      }
    }
  }

  handlePaste(self, e) {
    let verifyOtp = getShadowRoot().querySelector(`#${self.formId}`);
    var clipboardData, pastedData;

    // Stop data actually being pasted into div
    e.stopPropagation();
    e.preventDefault();

    // Get pasted data via clipboard API
    clipboardData = e.clipboardData || window.clipboardData;
    pastedData = clipboardData.getData("Text");

    // Do whatever with pasteddata
    if (pastedData && pastedData.length === 6) {
      Array.from(pastedData).forEach((val, index) => {
        if (!isNaN(val))
          verifyOtp.querySelectorAll("input.identity-otp")[index].value = val;
      });
    }
  }

  resetOtpValue() {
    let verifyOtp = getShadowRoot().querySelector(`#${this.formId}`);
    let elements = verifyOtp.querySelectorAll("input.identity-otp");
    elements.forEach(function (element) {
      element.value = "";
    });
  }

  getOtpValue() {
    let verifyOtp = getShadowRoot().querySelector(`#${this.formId}`),
      otpValue = "";
    let elements = verifyOtp.querySelectorAll("input.identity-otp");
    elements.forEach(function (element) {
      if (element.value) otpValue += element.value;
    });
    return otpValue;
  }

  bindEvents() {
    const self = this;
    let verifyOtp = getShadowRoot().querySelector(`#${this.formId}`);

    verifyOtp.addEventListener("submit", (event) => {
      event.preventDefault();
      let otpValue = self.getOtpValue(),
        sessionData = getSessionData();

      if (!otpValue || (otpValue && otpValue.length < 6)) {
        return setError("Please enter otp input.");
      } else if (otpValue && otpValue.length === 6) {
        setLoading(true);
        let isVerified = sessionData?.profileDetails?.isVerified ? true : false;
        const URL = isVerified
          ? `/auth/otp/verify/${getSessionId()}`
          : `/otp/verify/${getSessionId()}`;

        request
          .post(URL, { otp: otpValue })
          .then(({ data }) => {
            self.resetTimer();
            setSessionData(data.data);
            if (sessionData.showDocument)
              setSessionData({
                showDocument: false,
                profileDetails: {
                  ...sessionData.profileDetails,
                  isVerified: true,
                },
              });
            nextPrev(1);
            setLoading(false);
          })
          .catch(({ response }) => {
            if (
              response?.data &&
              response.data?.errors &&
              response.data.errors.INVALID_OTP
            ) {
              setLoading(false);
              setError(response.data.errors.INVALID_OTP[0]);
            } else if (
              response?.data &&
              response.data?.errors &&
              response.data.errors.KB_AUTH
            ) {
              self.resetTimer();
              setSessionData({
                kbAuth: true,
              });
              nextPrev(1);
            } else if (
              response?.data &&
              response.data?.errors &&
              response.data.errors.REDIRECT_BACK
            ) {
              self.resetTimer();
              setSessionData({
                showDocument: false,
                redirectBack: true,
              });
              nextPrev(1);
            } else if (
              isVerified &&
              response?.data &&
              response.data?.errors &&
              response.data.errors.SHOW_DOCUMENT
            ) {
              self.resetTimer();
              setSessionData({
                showDocument: true,
                profileDetails: {
                  ...sessionData.profileDetails,
                  isVerified: false,
                },
              });
              nextPrev(1);
            } else if (
              isVerified &&
              response?.data &&
              response.data?.errors &&
              response.data.errors.VERIFY_PUBLIC_DATA
            ) {
              request
                .post(`/publicData/${getSessionId()}`)
                .then(async ({ data }) => {
                  self.resetTimer();
                  setSessionData({
                    ownershipVerificationDetails: data.data,
                    profileDetails: {
                      ...sessionData.profileDetails,
                      isVerified: false,
                    },
                  });
                  nextPrev(1);
                })
                .finally(() => setLoading(false));
            } else {
              setLoading(false);
              setError(response);
            }

            this.resetOtpValue();
          });
      }
    });

    self.countDownTimer();

    verifyOtp
      .querySelector(`#resendOtpLink`)
      .addEventListener("click", self.resendOtp.bind(this));

    let elements = verifyOtp.querySelectorAll("input.identity-otp");
    elements.forEach(function (element, index) {
      if (index === 0) {
        element.addEventListener("paste", (event) =>
          self.handlePaste(self, event)
        );
      }
      element.addEventListener("keyup", (event) =>
        self.handleFocus(index, event)
      );
    });
  }
}

export default VerifyOtp;
