/* eslint-disable import/no-unresolved */
// import "@aws-amplify/ui-react/styles.css";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { Amplify } from "aws-amplify";
import awsexports from "../utils/aws-exports";
import React from "react";
import { getSessionData, getShadowRoot } from "../utils/store";
const { forwardRef, useImperativeHandle } = React;

Amplify.configure(awsexports);

export const AwsLiveness = forwardRef((props, ref) => {
  const [loading, setLoading] = React.useState(true);
  const [showLiveness, toggleLiveness] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [isError, setError] = React.useState(false);
  const [livenessSessionId, setCreateLivenessApiData] = React.useState(null);

  React.useEffect(() => {
    const fetchCreateLiveness = async () => {
      /*
       * This should be replaced with a real call to your own backend API
       */
      //   await new Promise((r) => setTimeout(r, 2000));
      const sessionData = getSessionData();
      if (sessionData?.livenessSessionId)
        setCreateLivenessApiData(sessionData.livenessSessionId);
      setLoading(false);
    };

    fetchCreateLiveness();
  }, [showLiveness]);

  useImperativeHandle(ref, () => ({
    startLiveness() {
      if (isError) setLivenessError(false);
      const sessionData = getSessionData();
      if (sessionData?.livenessSessionId)
        setCreateLivenessApiData(sessionData.livenessSessionId);
      toggleLiveness(true);
    },

    setLivenessError(val = true) {
      setError(val);
      if (val) setSuccess(false);
    },
  }));

  const handleAnalysisComplete = async () => {
    if (isError) setLivenessError(false);
    setSuccess(true);
    toggleLiveness(false);
    // setLoading(true);
    props.successCallback();
  };

  const onUserCancel = async () => {
    toggleLiveness(false);
    setLivenessError(false);
    props.toggleCameraClass(false);

    // show tryAnotherWay link
    const sessionData = getSessionData();
    if (sessionData?.isVerified) {
      let selfieVerification = getShadowRoot().querySelector(`#${props.formId}`);
      if (selfieVerification) {
        let tryAnotherWay = selfieVerification.querySelector("#tryAnotherWay");
        if (tryAnotherWay) tryAnotherWay.style.display = "block";
      }
    }
  };

  const errorCallback = async (errorObj) => {
    setLivenessError(true);
    toggleLiveness(false);
    if(errorObj.state === "CAMERA_ACCESS_ERROR")
      props.errorCallback("Oops! It seems that browser permission was denied previously. You can resolve this by navigating to your 'Website Settings -> Permissions' section and granting access to the camera. Once done, please retry the verification process.")
    else
      props.errorCallback(errorObj.error.message);
  };

  const setLivenessError = async (val = true) => {
    setError(val);
    if (val) setSuccess(false);
  };

  return (
    <>
      <div
        className="face-me-sdk-loading"
        id="faceMeSdkLoading"
        style={{ display: loading ? "flex" : "none" }}
      >
        <svg
          className="svg"
          width="100px"
          height="100px"
          aria-hidden="true"
          role="img"
        >
          <use
            href="#svg__loader"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="#svg__loader"
          ></use>
        </svg>
      </div>
      {showLiveness ? (
        <FaceLivenessDetector
          sessionId={livenessSessionId}
          region={"us-east-1"}
          onAnalysisComplete={handleAnalysisComplete}
          onUserCancel={onUserCancel}
          disableInstructionScreen={true}
          onError={errorCallback}
        />
      ) : !isSuccess && !isError ? (
        <div
          className="identity__outline-box identity__por identity__r-6"
          id="sdk_block"
          style={{ height: "100%", width: "100%" }}
          onClick={props.initializeLiveness}
        >
          <span className="identity__poa identity__left identity__right identity__bottom identity__text-center identity__label">
            Tap to start live selfie verification
          </span>
          <div className="identity__outline-inner identity__height-2x">
            <svg
              id="faceMeSdkCameraIcon"
              className="svg"
              width="60px"
              height="60px"
              aria-hidden="true"
              role="img"
            >
              <use
                href="#svg__camera"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xlinkHref="#svg__camera"
              ></use>
            </svg>
          </div>
        </div>
      ) : null}
    </>
  );
});

export default AwsLiveness;
