import { setError, setLoading } from "../utils";
import { nextPrev, setCurrentStep } from "../utils/StepService";
import { request } from "../utils/axios";
import { getSessionId, getShadowRoot } from "../utils/store";
import FormValidator from "./FormValidator";

class MoblieVerificationLink {
  validator = null;
  render() {
    let modalBox = getShadowRoot().querySelector("#identity-modal__box");

    if (modalBox.querySelector("#mobileVerificationLink")) return;

    modalBox.insertAdjacentHTML(
      "beforeend",
      `<form class="form-content mobile-verification-qr" id="mobileVerificationLink" data-form-tab>
                <div class="identity__row">
                    <div class="identity__col-12 identity__text-center">
                        <h3 class="identity__h3">Mobile Verification</h3>
                    </div>
                    <div class="identity__col-12 identity__text-center">
                        <p>We'll send you the link where you can complete the verification process using your phone's camera to take a picture of your ID.</p>
                    </div>
                    <div class="identity__col-12 identity__text-center">
                        <div class="identity-field">
                            <svg class="svg" width="62.454" height="105.691" aria-hidden="true" role="img" fill="#183956">
                                <use href="#svg__phone" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__phone" >
                                </use>
                            </svg>
                        </div>
                    </div>
                    <div class="identity__col-12 identity__text-center">
                        <div class="identity-field floating-field">
                            <input name="phoneNumber" id="phoneNumber" type="tel" placeholder="none" class="identity-input input" maxlength="14" onkeypress="return /[0-9]/i.test(event.key)" />
                            <label for="phoneNumber" class="identity-floating-label">Enter your phone number*</label>
                            <svg class="icon icon-error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                   <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                               </svg>
                            <span class="error-message"></span>
                        </div>
                    </div>
                    <div class="identity__col-12 identity__text-center">
                        <button class="identity-btn" type="submit" id="identity__btnSendLinkToPhone">
                            SEND LINK
                            <span class="loading-icon">
                                <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img">
                                  <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            `
    );

    this.bindEvents();
  }

  bindEvents() {
    let sendLinkForm = getShadowRoot().querySelector("#mobileVerificationLink");
    sendLinkForm.addEventListener("submit", async (event) => {
      event.preventDefault();
      

      let fields = {
        phoneNumber: {
          required: true,
        },
      };

      this.validator = new FormValidator(event.target, fields);

      let validData = this.validator.validate();
      if (validData.isValid) {
        validData.data.phoneNumber = validData.data.phoneNumber.replace(
          /[^\d\+]/g,
          ""
        );

        try {
            setLoading(true);
          let res = await request.post(`/mobile/link/${getSessionId()}`, {
            ...validData.data,
          });

          if (res && res.data && res.data.data) {
            await setCurrentStep("mobileVerificationInitiated");
            await nextPrev(1);
          }
        } catch (e) {
          setError(e);
        }
        finally{
            setLoading(false);
        }
      }
      
    });

    const handleKeyDownCallback = (e) => {
      var evt = e || window.event;
      var keyCode = evt.keyCode || evt.which;
      // let keyCode = e.keyCode;
      if(e.target != null && e.target.value != undefined){
        let val = e.target.value;
      e.target.value = val
        .replace(/\D/g, "")
        .replace(/^0+/, "")
        .replace(/(\d{1,3})(\d{1,3})?(\d{1,4})?/g, function (txt, f, s, t) {
          if (t) {
            return `(${f})-${s}-${t}`;
          } else if (s) {
            return `(${f})-${s}`;
          } else if (keyCode === 8 && f.length > 0 && f.length <= 3) {
            return `(${f}`;
          } else if (f) {
            return `(${f})`;
          }
        });
      }
    };

    var phoneNumberInp = sendLinkForm
      .querySelector("#phoneNumber");
      phoneNumberInp.addEventListener("keydown", function (e) {
        var evt = e || window.event;
        var keyCode = evt.keyCode || evt.which;
        if (keyCode) handleKeyDownCallback(e);
        else setTimeout(() => handleKeyDownCallback(e), 100);
      });
      phoneNumberInp.addEventListener("paste",(e)=>{
        setTimeout(() => handleKeyDownCallback(e), 100);
      });
  }
}

export default MoblieVerificationLink;
