import {
  getSessionId,
  setSessionData,
  getProfileToken,
  setProfileToken,
  getShadowRoot,
} from "../utils/store";
import { request } from "../utils/axios";
import { nextPrev, closeSession } from "../utils/StepService";
import { setLoading, setError, uuidv4 } from "../utils";
import { getDialogState, setRedirectUri } from "../utils/DialogService";

class VerifyCode {
  constructor() {
    this.interval = null;
    this.timer = 30;
  }

  render() {
    let modalBox = getShadowRoot().querySelector("#identity-modal__box");
    this.formId = `verifyCodeContent-${uuidv4()}`;

    modalBox.insertAdjacentHTML(
      "beforeend",
      `<form class="form-content verify-code" method="post" id="${this.formId}" data-form-tab>
                                <h3>Verification Code</h3>
                                <div class="identity__row">
                                <div class="identity__col-12">
                                  <div class="identity-alert__error" id="identity-alert__error" style="display:none;">
                                    <button data-close="close" style="display:none;" type="button">&times;</button>
                                    <svg class="icon" width="20px" height="20px" aria-hidden="true" role="img"><use href="#svg__alert-error" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__alert-error"></use></svg>
                                    <div id="identity-alert__message" data-text="text"></div>
                                  </div>
                                </div>
                                <div class="identity__col-12">
                                    <div class="identity__row identity__otp">
                                    <div class="identity__col">
                                        <div class="identity-field">
                                        <input onkeypress="return /^[a-z0-9]+$/i.test(event.key)" maxlength="1" class="identity-input identity-otp" />
                                        </div>
                                    </div>
                                    <div class="identity__col">
                                        <div class="identity-field">
                                        <input onkeypress="return /^[a-z0-9]+$/i.test(event.key)" maxlength="1" class="identity-input identity-otp" />
                                        </div>
                                    </div>
                                    <div class="identity__col">
                                        <div class="identity-field">
                                        <input onkeypress="return /^[a-z0-9]+$/i.test(event.key)" maxlength="1" class="identity-input identity-otp" />
                                        </div>
                                    </div>
                                    <div class="identity__col">
                                        <div class="identity-field">
                                        <input onkeypress="return /^[a-z0-9]+$/i.test(event.key)" maxlength="1" class="identity-input identity-otp" />
                                        </div>
                                    </div>
                                    <!--<div class="identity__col">
                                        <div class="identity-field">
                                        <input type="tel" onkeypress="return /[0-9]/i.test(event.key)" maxlength="1" class="identity-input identity-otp" />
                                        </div>
                                    </div>
                                    <div class="identity__col">
                                        <div class="identity-field">
                                        <input type="tel" onkeypress="return /[0-9]/i.test(event.key)" maxlength="1" class="identity-input identity-otp" />
                                        </div>
                                    </div>-->
                                    </div>
                                </div>
                                <div class="identity__col-12">
                                    <div class="identity-field">
                                        <button id="btn-continue" type="submit" class="identity-btn btn-continue">Verify Now
                                            <span class="loading-icon">
                                                <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img">
                                                <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                </div>
                            </form>`
    );

    this.bindEvents();
  }

  resetTimer() {
    if (this.interval) clearInterval(this.interval);
    this.timer = 30;
  }

  handleFocus(index, event) {
    let verifyCode = getShadowRoot().querySelector(`#${this.formId}`);
    if (event.keyCode === 8 || event.keyCode === 46) {
      let element =
        verifyCode.querySelectorAll("input.identity-otp")[index - 1];
      if (element) {
        element.focus();
        element.select();
      }
    }
    if (event.target.value) {
      let element =
        verifyCode.querySelectorAll("input.identity-otp")[index + 1];
      if (element) {
        element.focus();
        element.select();
      }
    }
  }

  handlePaste(self, e) {
    let verifyCode = getShadowRoot().querySelector(`#${self.formId}`);
    var clipboardData, pastedData;

    // Stop data actually being pasted into div
    e.stopPropagation();
    e.preventDefault();

    // Get pasted data via clipboard API
    clipboardData = e.clipboardData || window.clipboardData;
    pastedData = clipboardData.getData("Text");

    // Do whatever with pasteddata
    if (pastedData && pastedData.length === 4) {
      Array.from(pastedData).forEach((val, index) => {
        // if (!isNaN(val))
        verifyCode.querySelectorAll("input.identity-otp")[index].value = val;
      });
      verifyCode.querySelectorAll("input.identity-otp")[3].focus();
    }
  }

  resetCodeValue() {
    let verifyCode = getShadowRoot().querySelector(`#${this.formId}`);
    let elements = verifyCode.querySelectorAll("input.identity-otp");
    elements.forEach(function (element) {
      element.value = "";
    });
  }

  getCodeValue() {
    let verifyCode = getShadowRoot().querySelector(`#${this.formId}`),
      codeValue = "";
    let elements = verifyCode.querySelectorAll("input.identity-otp");
    elements.forEach(function (element) {
      if (element.value) codeValue += element.value;
    });
    return codeValue;
  }

  getRedirectUri() {
    request
      .get(`/redirectUri/${getSessionId()}`)
      .then(({ data }) => {
        setRedirectUri(data.data);
      })
      .catch(() => {
        var dialogState = getDialogState();
        closeSession();
        dialogState.errorCallback();
      });
  }

  getSessionDetail = () => {
    return request
      .get(`/Details/${getSessionId()}`)
      .then(({ data }) => {
        if (data.data) {
          setSessionData(
            {
              ...data.data,
              ownershipVerificationDetails:
                data.data.profileDetails &&
                data.data.profileDetails.ownershipVerificationDetails,
            },
            true
          );
        }
        return data;
      })
      .catch(() => {
        var dialogState = getDialogState();
        closeSession();
        dialogState.errorCallback();
      });
  };

  bindEvents() {
    const self = this;
    let verifyCode = getShadowRoot().querySelector(`#${this.formId}`);

    verifyCode.addEventListener("submit", (event) => {
      event.preventDefault();
      let codeValue = self.getCodeValue();
      // dialogState = getDialogState();

      if (!codeValue || (codeValue && codeValue.length < 4)) {
        return setError("Please enter code.");
      } else if (codeValue && codeValue.length === 4) {
        let profileToken = getProfileToken();
        setLoading(true);
        request
          .post(`/Support/session`, {
            profileToken: profileToken,
            verificationCode: codeValue,
          })
          .then(async ({ data }) => {
            if (data.data && data.data?.isVerified) {
              try {
                setProfileToken(null);
                /* Unset support verification parameters on successful verification */
                setSessionData({
                  ...data.data,
                  showSupportVerificationError: false,
                  supportVerificationError: "",
                  nextVerificationStep: "Success",
                });

                // setSessionId(profileToken);
                // if (dialogState.fullScreen) await this.getRedirectUri();
                setRedirectUri(window.location.origin); // For hide continue btn on success screen
                this.resetCodeValue();
                nextPrev(1);
              } catch (err) {
                console.log("err", err);
              } finally {
                setLoading(false);
              }
            }
          })
          .catch(({ response }) => {
            if (
              response?.data &&
              response.data?.errors &&
              response.data.errors.INVALID_OTP
            ) {
              setLoading(false);
              setError(response.data.errors.INVALID_OTP[0]);
            } else if (
              response?.data &&
              response.data?.errors &&
              response.data.errors.REDIRECT_BACK
            ) {
              setSessionData({
                redirectBack: true,
              });
              nextPrev(1);
            } else {
              setLoading(false);
              setError(response);
            }

            this.resetCodeValue();
          });
      }
    });

    let elements = verifyCode.querySelectorAll("input.identity-otp");
    elements.forEach(function (element, index) {
      if (index === 0) {
        element.addEventListener("paste", (event) =>
          self.handlePaste(self, event)
        );
      }
      element.addEventListener("keyup", (event) =>
        self.handleFocus(index, event)
      );
    });
  }
}

export default VerifyCode;
