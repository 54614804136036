import { getSessionId, getSessionData, setSessionData, getShadowRoot } from "../utils/store";
import { request } from "../utils/axios";
import { nextPrev } from "../utils/StepService";
import { setError } from "../utils";
import { setLoading, formatPhoneNumber, uuidv4 } from "../utils";

class AddressSelection {
  constructor() {
    this.selected = null;
    this.kbType = 0;
  }
  render = async () => {
    await this.getAddressList(false);
    const sessionData = getSessionData();
    this.kbType = sessionData.kbType || 0;
    const options = this.setAddressOptions();
    this.formId = `addressSelectionContent-${uuidv4()}`;
    let modalBox = getShadowRoot().querySelector("#identity-modal__box");
    // if (modalBox.querySelector("#addressSelectionContent")) return;

    modalBox.insertAdjacentHTML(
      "beforeend",
      `<form class="form-content address-selection-form" method="post" id="${
        this.formId
      }" data-form-tab>
                                <h3>Select ${
                                  this.kbType === 2
                                    ? "Phone Number"
                                    : this.kbType === 1
                                    ? "Email"
                                    : "Address"
                                }</h3>
                                <div class="identity__row">
                                  <div class="identity__col-12">
                                    <div class="identity-alert__error" id="identity-alert__error" style="display:none;">
                                      <button data-close="close" style="display:none;" type="button">&times;</button>
                                      <svg class="icon" width="20px" height="20px" aria-hidden="true" role="img"><use href="#svg__alert-error" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__alert-error"></use></svg>
                                      <div data-text="text" id="identity-alert__message"></div>
                                    </div>
                                  </div>
                                  <div class="identity__col-12">
                                    <!--<ul class="identity__select-list">
                                    <li>29282 Ha****** Rd, Athens AL 35611</li>
                                    <li class="selected">29282 Ha****** Rd, Athens AL 35611</li>
                                    <li>29282 Ha****** Rd, Athens AL 35611</li>
                                    </ul>-->
                                    ${
                                      options
                                        ? `<ul class="identity__select-list">
                                      ${options || ""}
                                    </ul>`
                                        : ""
                                    }
                                    <div class="identity__inner-loader" style="display:none;">
                                      <svg class="svg" width="100px" height="100px" aria-hidden="true" role="img">
                                        <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                                      </svg>
                                    </div>
                                  </div>
                                  <div class="identity__col-7">
                                    <div class="identity-field">
                                      <button id="btn-refresh" type="button" class="identity-btn identity-btn__secondary">
                                        <svg style="vertical-align: top;margin-right: 5px;" class="svg" width="16px" height="16px" aria-hidden="true" role="img"><use href="#svg__refresh" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__refresh"></use></svg>Refresh Options
                                        <span class="loading-icon">
                                          <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img">
                                            <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                                          </svg>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                  <div class="identity__col-5">
                                    <div class="identity-field">
                                      <button id="btn-continue" type="submit" class="identity-btn btn-continue">Continue
                                        <span class="loading-icon">
                                          <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img">
                                            <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                                          </svg>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                  ${
                                    sessionData?.isVerified === true
                                      ? `<div class="identity__col-12">
                                        <p class="identity__text-center identity__m0">
                                          <a id="tryAnotherWay">Try another way ?</a>
                                        </p>
                                      </div>`
                                      : ''
                                  }
                                </div>
                            </form>`
    );
    this.bindEvents();
  };

  setAddressOptions() {
    const sessionData = getSessionData();
    let result;
    if (sessionData.addressList && sessionData.addressList.length) {
      result = "";
      for (let i = 0; i < sessionData.addressList.length; i++) {
        let addressObj = sessionData.addressList[i];
        // let className = i == 0 ? 'selected' : 'option';
        result += `<li value="${btoa(addressObj.id)}" class="option">${
          this.kbType === 2
            ? formatPhoneNumber(addressObj.option)
            : addressObj.option
        }</li>`;
      }
    }
    return result;
  }

  getAddressList = (reload = false) => {
    const sessionData = getSessionData();
    return request
      .get(`/kb/${getSessionId()}?reload=${reload}`)
      .then(({ data }) => {
        if (data.data)
          setSessionData(
            { ...data.data, addressList: data.data?.options || [], isVerified: sessionData?.nextVerificationStep === "KBForVerified" },
            true
          );

        return data;
      })
      .catch(({ response }) => {
        setError(response);
      });
  };

  selectOption(event) {
    if (!event.target.classList.contains("selected")) {
      const addressSelectionForm = getShadowRoot().querySelector(`#${this.formId}`);
      addressSelectionForm
        .querySelector("ul.identity__select-list li.selected")
        .classList.remove("selected");
      event.target.classList.add("selected");
      this.selected = atob(event.target.getAttribute("value"));
    }
  }

  async refreshHandler() {
    const addressSelectionForm = getShadowRoot().querySelector(`#${this.formId}`);
    const addressList = addressSelectionForm.querySelector(
        ".identity__select-list"
      ),
      loader = addressSelectionForm.querySelector(".identity__inner-loader");

    let addressOffset = addressList.getBoundingClientRect();
    addressList.style.display = "none";
    loader.style.display = "flex";
    loader.style.height = addressOffset.height + "px";

    await this.getAddressList(true);
    const newOptions = this.setAddressOptions();

    addressList.innerHTML = newOptions;
    loader.style.display = "none";
    addressList.style.display = "block";

    // selected First option
    let options = addressSelectionForm.querySelectorAll(
      "ul.identity__select-list li.option"
    );
    if (options && options.length) {
      options.forEach((option, index) => {
        if (index === 0) {
          option.classList.add("selected");
          this.selected = atob(option.getAttribute("value"));
        }
        option.addEventListener("click", this.selectOption.bind(this));
      });
    }

    // toggle disable refresh button
    const sessionData = getSessionData();
    let btnRefresh = addressSelectionForm.querySelector("#btn-refresh");
    if (!sessionData.isReloadPossible)
      btnRefresh.setAttribute("disabled", true);
    else btnRefresh.removeAttribute("disabled");
  }

  async tryAnotherWayHandler() {
    const addressSelectionForm = getShadowRoot().querySelector(`#${this.formId}`);
    const addressList = addressSelectionForm.querySelector(
        ".identity__select-list"
      ),
      loader = addressSelectionForm.querySelector(".identity__inner-loader");

    let addressListOffset = addressList.getBoundingClientRect();
    addressList.style.display = "none";
    loader.style.display = "flex";
    loader.style.height = addressListOffset.height + "px";

    await request
      .post(`/auth/tryanother/${getSessionId()}`)
      .then(({ data }) => {
        setSessionData({ ...data.data });
        nextPrev(1);
      })
      .catch(({ response }) => {
        setError(response);
      });

    loader.style.display = "none";
    addressList.style.display = "block";
  }

  bindEvents() {
    const addressSelectionForm = getShadowRoot().querySelector(`#${this.formId}`),
      tryAnotherWay = addressSelectionForm.querySelector("#tryAnotherWay");

    addressSelectionForm.addEventListener("submit", (event) => {
      event.preventDefault();

      if (this.selected) {
        setLoading(true);
        request
          .post(`/kb/${getSessionId()}`, {
            chosenOptionId: this.selected,
          })
          .then(({ data }) => {
            setSessionData({ ...data.data, kbAuth: false });
            nextPrev(1);
          })
          .catch(({ response }) => {
            setError(response);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });

    // selected First option
    let options = addressSelectionForm.querySelectorAll(
      "ul.identity__select-list li.option"
    );
    if (options && options.length) {
      options.forEach((option, index) => {
        if (index === 0) {
          option.classList.add("selected");
          this.selected = atob(option.getAttribute("value"));
        }
        option.addEventListener("click", this.selectOption.bind(this));
      });
    }

    let btnRefresh = addressSelectionForm.querySelector("#btn-refresh");
    btnRefresh.addEventListener("click", this.refreshHandler.bind(this));

    const sessionData = getSessionData();
    if (!sessionData.isReloadPossible)
      btnRefresh.setAttribute("disabled", true);
    else btnRefresh.removeAttribute("disabled");

    if (tryAnotherWay)
      tryAnotherWay.addEventListener(
        "click",
        this.tryAnotherWayHandler.bind(this)
      );
  }
}

export default AddressSelection;
