import { getSessionId, getShadowRoot, setSessionData } from "../utils/store";
import { request } from "../utils/axios";
import { nextPrev } from "../utils/StepService";
import { setError, setLoading } from "../utils";
import Cropper from "cropperjs";
import croppercss from "cropperjs/dist/cropper.css";
// import { getDialogState } from "../utils/DialogService";

class UploadDocument {
  // formData = {
  //   frontBase64: "",
  //   backBase64: "",
  // };
  formData = new FormData();
  cropperInstance = null;
  previewData = null;
  cropDimensions = {};

  render() {
    let modalBox = getShadowRoot().querySelector("#identity-modal__box");

    if (modalBox.querySelector("#uploadDocumentContent")) return;

    const cropperStyleSheet= document.createElement("style");
    cropperStyleSheet.textContent = croppercss;

    getShadowRoot().appendChild(cropperStyleSheet);
    modalBox.insertAdjacentHTML(
      "beforeend",
      `<form class="form-content upload-document" method="post" enctype="multipart/form-data" id="uploadDocumentContent" data-form-tab>
                                <h3>Upload Your ID Card</h3>
                                <div class="identity__row">
                                  <div class="identity__col-12">
                                    <div class="identity-alert__error" id="identity-alert__error" style="display:none;">
                                      <button data-close="close" style="display:none;" type="button">&times;</button>
                                      <svg class="icon" width="20px" height="20px" aria-hidden="true" role="img"><use href="#svg__alert-error" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__alert-error"></use></svg>
                                      <div id="identity-alert__message" data-text="text"></div>
                                    </div>
                                  </div>
                                <div class="identity__col-12" id="croppingArea" style="display:none;">
                                  <div class="identity-field">
                                    <div class="identity__por identity__r-6">
                                      <div class="identity__outline-crop-preview">
                                        <img src="https://via.placeholder.com/150" class="uploaded-doc-preview" id="cropImage" style="width: 100%;max-height:300px;" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="identity__col-12" id="uploadDocumentArea">
                                  <div class="identity-field">
                                   <div class="identity__outline-box identity__por identity__r-6">
                                        <input type="file" id="uploadFrontFile" accept="camera,image/*" style="display:none" capture/>
                                        <span class="identity__poa identity__left identity__top  identity__badge">Front</span>
                                        <span class="identity__poa identity__left identity__right identity__bottom identity__text-center identity__label">Choose photo of your government issued ID</span>
                                        <div class="identity__outline-inner">
                                        <svg class="svg" width="52px" height="52px" aria-hidden="true" role="img"><use href="#svg__upload" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__upload"></use></svg>
                                        
                                        </div>
                                        <div class="identity__outline-inner-preview" style="display:none;">
                                          <div class="edit-doc">
                                            <svg class="svg" width="52px" height="52px" aria-hidden="true" role="img"><use href="#svg__pencil" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__pencil"></use></svg>
                                          </div>
                                          <div class="crop-doc front" id="cropDocFront" >
                                            <svg class="svg" width="52px" height="52px" aria-hidden="true" role="img"><use href="#svg__crop" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__crop"></use></svg>
                                          </div>
                                          <img src="https://via.placeholder.com/150" class="uploaded-doc-preview"  />
                                        </div>
                                    </div>
                                    </div>
                                    <div class="identity-field">
                                    <div class="identity__outline-box identity__por identity__r-6">
                                        <input type="file" id="uploadBackFile" accept="camera,image/*" style="display:none" capture/>
                                        <span class="identity__poa identity__left identity__top  identity__badge">Back</span>
                                        <span class="identity__poa identity__left identity__right identity__bottom identity__text-center identity__label">Choose photo of your government issued ID</span>
                                        <div class="identity__outline-inner">
                                        <svg class="svg" width="52px" height="52px" aria-hidden="true" role="img"><use href="#svg__upload" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__upload"></use></svg>
                                        </div>
                                        <div class="identity__outline-inner-preview" style="display:none;">
                                        <div class="edit-doc">
                                            <svg class="svg" width="52px" height="52px" aria-hidden="true" role="img"><use href="#svg__pencil" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__pencil"></use></svg>
                                          </div>
                                          <div class="crop-doc back" id="cropDocBack">
                                          <svg class="svg" width="52px" height="52px" aria-hidden="true" role="img"><use href="#svg__crop" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__crop"></use></svg>
                                          </div>
                                          <img src="https://via.placeholder.com/150" class="uploaded-doc-preview"/>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="identity__col-12">
                                  <div class="identity__row column-reverse-xs" id="cropAreaButtons" style="display:none;">
                                    <div class="identity__col-6">
                                        <div class="identity-field last">
                                          <button type="button" class="identity-btn identity-btn__secondary" id="btnCropCancel">Cancel</button>
                                        </div>
                                    </div>
                                    <div class="identity__col-6">
                                      <div class="identity-field">
                                        <button type="button" class="identity-btn btn-continue" id="btnCropContinue">
                                          Crop Image
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="identity__row column-reverse-xs" id="uploadAreaButtons">
                                   
                                    <div class="identity__col-12">
                                      <div class="identity-field">
                                        <button disabled type="submit" id="btn-continue" class="identity-btn btn-continue">
                                          Continue
                                          <span class="loading-icon">
                                            <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img">
                                              <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                                            </svg>
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>`
    );

    this.bindEvents();
  }

  btnCancelCallback(e) {
    const uploadDocumentForm = getShadowRoot().querySelector("#uploadDocumentContent");
    // Remove crop dimension on cancel button click
    if (this.previewData?.type === "front") delete this.cropDimensions.front;
    else if (this.previewData?.type === "back") delete this.cropDimensions.back;
    var base64Img = "";
    const croppingArea = uploadDocumentForm.querySelector("#croppingArea");
    const uploadDocumentArea = uploadDocumentForm.querySelector(
      "#uploadDocumentArea"
    );
    const cropAreaButtons =
      uploadDocumentForm.querySelector("#cropAreaButtons");
    const uploadAreaButtons =
      uploadDocumentForm.querySelector("#uploadAreaButtons");
    croppingArea.style.display = "none";
    cropAreaButtons.style.display = "none";
    uploadDocumentArea.style.display = "block";
    uploadAreaButtons.style.display = "flex";

    this.cropperInstance.destroy();
    // cropImage.src = "";
    let targetImage = uploadDocumentForm.querySelectorAll(
      "div.identity__outline-inner-preview"
    );
    if (this.previewData.type === "front") {
      targetImage = targetImage[0];
      // this.formData.frontBase64 = base64Img.split("base64,")[1];
    } else if (this.previewData.type === "back") {
      targetImage = targetImage[1];
      // this.formData.backBase64 = base64Img.split("base64,")[1];
    }

    if (targetImage) {
      targetImage.querySelector(".uploaded-doc-preview").src =
        this.previewData.base64Img;
      targetImage.style.display = "flex";
    }
  }

  btnContinueCallback(e) {
    const uploadDocumentForm = getShadowRoot().querySelector("#uploadDocumentContent");
    var croppedResult = this.cropperInstance
      .getCroppedCanvas({
        imageSmoothingEnabled: false,
        imageSmoothingQuality: "high",
      })
      .toDataURL("image/jpeg");

    this.cropperInstance.destroy();
    this.cropperInstance = null;

    let targetImage = uploadDocumentForm.querySelectorAll(
      "div.identity__outline-inner-preview"
    );
    if (this.previewData.type === "front") {
      targetImage = targetImage[0];
      // this.formData.frontBase64 = base64Img.split("base64,")[1];
    } else if (this.previewData.type === "back") {
      targetImage = targetImage[1];
      // this.formData.backBase64 = base64Img.split("base64,")[1];
    }

    if (targetImage) {
      targetImage.querySelector(".uploaded-doc-preview").src = croppedResult;
      targetImage.style.display = "flex";
    }
    // showHideCropBox(false);
    const croppingArea = uploadDocumentForm.querySelector("#croppingArea");
    const uploadDocumentArea = uploadDocumentForm.querySelector(
      "#uploadDocumentArea"
    );
    const cropAreaButtons =
      uploadDocumentForm.querySelector("#cropAreaButtons");
    const uploadAreaButtons =
      uploadDocumentForm.querySelector("#uploadAreaButtons");
    croppingArea.style.display = "none";
    cropAreaButtons.style.display = "none";
    uploadDocumentArea.style.display = "block";
    uploadAreaButtons.style.display = "flex";

    // console.log("croppedResult", croppedResult);
    // if (this.formData.has(this.previewData.type))
    //   this.formData.delete(this.previewData.type);
    // this.formData.append(this.previewData.type, file);
  }

  setCroppedImageDimension(self) {
    if (self.formData.has("FrontX")) self.formData.delete("FrontX");
    if (self.formData.has("FrontY")) self.formData.delete("FrontY");
    if (self.formData.has("FrontHeight")) self.formData.delete("FrontHeight");
    if (self.formData.has("FrontWidth")) self.formData.delete("FrontWidth");
    if (self.cropDimensions?.front) {
      self.formData.append(
        "FrontX",
        parseFloat(self.cropDimensions.front.x.toFixed(2))
      );
      self.formData.append(
        "FrontY",
        parseFloat(self.cropDimensions.front.y.toFixed(2))
      );
      self.formData.append(
        "FrontHeight",
        parseFloat(self.cropDimensions.front.height.toFixed(2))
      );
      self.formData.append(
        "FrontWidth",
        parseFloat(self.cropDimensions.front.width.toFixed(2))
      );
    }
    if (self.formData.has("BackX")) self.formData.delete("BackX");
    if (self.formData.has("BackY")) self.formData.delete("BackY");
    if (self.formData.has("BackHeight")) self.formData.delete("BackHeight");
    if (self.formData.has("BackWidth")) self.formData.delete("BackWidth");
    if (self.cropDimensions?.back) {
      self.formData.append(
        "BackX",
        parseFloat(self.cropDimensions.back.x.toFixed(2))
      );
      self.formData.append(
        "BackY",
        parseFloat(self.cropDimensions.back.y.toFixed(2))
      );
      self.formData.append(
        "BackHeight",
        parseFloat(self.cropDimensions.back.height.toFixed(2))
      );
      self.formData.append(
        "BackWidth",
        parseFloat(self.cropDimensions.back.width.toFixed(2))
      );
    }
  }

  initCrop(type) {
    const self = this;

    let base64Img = this.frontBase64;

    if (type === "back") base64Img = this.backBase64;

    this.previewData = { base64Img, type };
    // if (this.formData.frontBase64 && this.formData.backBase64) {
    // if (this.formData.has("front") && this.formData.has("back")) {
    //   const submitBtn = document.querySelector(
    //     "#uploadDocumentContent button[type='submit']"
    //   );

    //   if (submitBtn) submitBtn.disabled = false;

    // }

    const uploadDocumentForm = getShadowRoot().querySelector("#uploadDocumentContent");
    const croppingArea = uploadDocumentForm.querySelector("#croppingArea");
    const uploadDocumentArea = uploadDocumentForm.querySelector(
      "#uploadDocumentArea"
    );
    const cropAreaButtons =
      uploadDocumentForm.querySelector("#cropAreaButtons");
    const uploadAreaButtons =
      uploadDocumentForm.querySelector("#uploadAreaButtons");
    const showHideCropBox = (value) => {
      croppingArea.style.display = value ? "block" : "none";
      cropAreaButtons.style.display = value ? "flex" : "none";
      uploadDocumentArea.style.display = value ? "none" : "block";
      uploadAreaButtons.style.display = value ? "none" : "flex";
    };
    showHideCropBox(true);

    const cropImage = croppingArea.querySelector("#cropImage");
    cropImage.src = base64Img;

    // if (!this.cropperInstance)
    this.cropperInstance = new Cropper(cropImage, {
      viewMode: 3,
      dragMode: "move",
      zoomable: false,
      data: self.cropDimensions[type] || null,
      crop: function (event) {
        if (self.previewData?.type === "front")
          self.cropDimensions.front = event.detail;
        else if (self.previewData?.type === "back")
          self.cropDimensions.back = event.detail;
      },
    });
    // else this.cropperInstance.replace(base64Img);
  }

  disableCroppingArea(loading) {
    const uploadDocumentForm = getShadowRoot().querySelector("#uploadDocumentContent");
    uploadDocumentForm
      .querySelectorAll("div.identity__outline-box")
      .forEach((element) => {
        if (loading) {
          element.classList.add("area-disabled");
        } else {
          element.classList.remove("area-disabled");
        }
      });
  }

  bindEvents() {
    const self = this;
    const uploadDocumentForm = getShadowRoot().querySelector("#uploadDocumentContent");

    /* const btnBack = uploadDocumentForm.querySelector("#btnBack");
    btnBack.addEventListener("click", () => {
      let dialogState = getDialogState(),
      Uri1 = getBaseUri(dialogState.redirectUri),
      Uri2 = window.location.host;

      if (dialogState.redirectUri && Uri1 && Uri1 != Uri2) {
        let uri = /[?&]/.test(dialogState.redirectUri) ? `${dialogState.redirectUri}&sessionId=${getSessionId()}` : `${dialogState.redirectUri}?sessionId=${getSessionId()}`
        window.location.href = uri;
      }
    }); */

    uploadDocumentForm.addEventListener("submit", (event) => {
      event.preventDefault();
      // let { frontBase64, backBase64 } = this.formData;
      if (this.formData.has("front") && this.formData.has("back")) {
        setLoading(true);
        self.disableCroppingArea(true);
        self.setCroppedImageDimension(self);
        request
          .post(`/Document/${getSessionId()}`, this.formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => {
            setSessionData(data.data);
            setLoading(false);
            nextPrev(1);
          })
          .catch(({ response }) => {
            if (
              response?.data &&
              response.data?.errors &&
              response?.data?.errors?.UNABLE_TO_READ_BACK
            ) {
              setLoading(false);
              setError(response.data.errors.UNABLE_TO_READ_BACK[0]);
            } else if (
              response?.data &&
              response.data?.errors &&
              response?.data?.errors?.REDIRECT_BACK
            ) {
              setLoading(false);
              setSessionData({
                redirectBack: true,
              });
              nextPrev(1);
            } else if (
              response?.data &&
              response.data?.errors &&
              response?.data?.errors?.ASK_TO_UPDATE_PERSONAL_INFO
            ) {
              setLoading(false);
              setSessionData({
                editPersonalInfo: true,
              });
              nextPrev(-1);
            } else {
              setLoading(false);
              setError(response);
            }
          })
          .finally(() => {
            self.disableCroppingArea(false);
          });
      } else {
        setError("Please Upload Your ID Card.");
      }
    });

    uploadDocumentForm
      .querySelector("#cropDocFront")
      .addEventListener("click", (event) => {
        event.stopPropagation();
        this.initCrop("front");
      });
    uploadDocumentForm
      .querySelector("#cropDocBack")
      .addEventListener("click", (event) => {
        event.stopPropagation();
        this.initCrop("back");
      });

    uploadDocumentForm
      .querySelectorAll("div.identity__outline-box")
      .forEach((element, index) => {
        element.addEventListener(
          "click",
          this.triggerFilePicker.bind(this, index === 0 ? "front" : "back")
        );
        element.addEventListener("dragover", this.dragOver);
        element.addEventListener("dragleave", this.dragLeave);
        element.addEventListener(
          "drop",
          this.getTheFile.bind(this, index === 0 ? "front" : "back")
        );
      });

    uploadDocumentForm
      .querySelector("#uploadFrontFile")
      .addEventListener("change", this.readFile.bind(this, "front"));
    uploadDocumentForm
      .querySelector("#uploadBackFile")
      .addEventListener("change", this.readFile.bind(this, "back"));

    const cropAreaButtons =
      uploadDocumentForm.querySelector("#cropAreaButtons");
    // const uploadAreaButtons = document.getElementById("uploadAreaButtons");
    const btnCropCancel = cropAreaButtons.querySelector("#btnCropCancel");
    const btnCropContinue = cropAreaButtons.querySelector("#btnCropContinue");
    btnCropCancel.addEventListener(
      "click",
      self.btnCancelCallback.bind(this),
      false
    );
    btnCropContinue.addEventListener(
      "click",
      self.btnContinueCallback.bind(this),
      false
    );
  }

  dragOver(event) {
    event.preventDefault();
    if (
      event.target.parentElement &&
      event.target.parentElement.closest("div.identity__outline-box")
    ) {
      event.target.parentElement
        .closest("div.identity__outline-box")
        .classList.add("drop-ready");
    }
  }

  dragLeave(event) {
    if (
      event.target.parentElement &&
      event.target.parentElement.closest("div.identity__outline-box")
    ) {
      event.target.parentElement
        .closest("div.identity__outline-box")
        .classList.remove("drop-ready");
    }
  }

  getTheFile(type, event) {
    event.preventDefault();
    this.readFile(type, event);
    this.dragLeave(event);
  }

  readFile(type, event) {
    const self = this;
    var files;
    if (event.target.files) {
      files = event.target.files;
    } else {
      files = event.dataTransfer.files;
    }
    var file = files[0];
    if (
      file &&
      file.type &&
      ["image/jpeg", "image/jpg", "image/png"].includes(file.type)
    ) {
      // console.log("file validated successfully", file);
      const reader = new FileReader();

      if (this.formData.has(type)) this.formData.delete(type);
      this.formData.append(type, file);

      delete this.cropDimensions[type];
      reader.onload = function (e) {
        self.previewImage(e.target.result, type);
      };
      reader.readAsDataURL(file);
      event.target.value = "";
    } else return;
  }

  previewImage(base64Img, type) {
    const uploadDocumentForm = getShadowRoot().querySelector("#uploadDocumentContent");
    let targetImage = uploadDocumentForm.querySelectorAll(
      "div.identity__outline-inner-preview"
    );

    if (type === "front") {
      targetImage = targetImage[0];
      this.frontBase64 = base64Img;
    } else if (type === "back") {
      targetImage = targetImage[1];
      this.backBase64 = base64Img;
    }

    if (targetImage) {
      targetImage.querySelector(".uploaded-doc-preview").src = base64Img;
      targetImage.style.display = "flex";
    }

    if (this.formData.has("front") && this.formData.has("back")) {
      const submitBtn = getShadowRoot().querySelector(
        "#uploadDocumentContent button[type='submit']"
      );

      if (submitBtn) submitBtn.disabled = false;
    }
    // this.initCrop(type);
  }

  triggerFilePicker(type, event) {
    const uploadDocumentForm = getShadowRoot().querySelector("#uploadDocumentContent");
    if (type === "front") {
      const targetFrontFile =
        uploadDocumentForm.querySelector("#uploadFrontFile");
      targetFrontFile.click();
    } else if (type === "back") {
      const targetBackFile =
        uploadDocumentForm.querySelector("#uploadBackFile");
      targetBackFile.click();
    }
  }
}

export default UploadDocument;
