import { getSessionData, getShadowRoot } from "../utils/store";
import { closeSession } from "../utils/StepService";
import { setError, uuidv4, supportBtnContent } from "../utils";

class SupportVerificationError {
  render() {
    let modalBox = getShadowRoot().querySelector("#identity-modal__box");
    this.formId = `supportVerificationErrorContent-${uuidv4()}`;

    modalBox.insertAdjacentHTML(
      "beforeend",
      `<div class="form-content" method="post" id="${this.formId}" data-form-tab>
        ${supportBtnContent}
      </div>`
    );

    this.bindEvents();
  }

  bindEvents() {
    const sessionData = getSessionData();
    let parent = getShadowRoot().querySelector(`#${this.formId}`);

    parent
      .querySelector("#btn-support")
      .addEventListener("click", closeSession);

    setError(sessionData.supportVerificationError);
  }
}

export default SupportVerificationError;
