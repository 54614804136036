let initialState = {
    sessionId: null,
    sessionData: null,
    profileToken: null,
    apiKey : null,
    initSessionOptions : null
  },
  state = initialState,
  shadowRoot = null;

export const setSessionId = (sessionId) =>
  (state = {
    ...state,
    sessionId,
  });

export const getSessionId = () => state.sessionId;

export const getStoreData = () => state;

export const setSessionData = (sessionData, reset = false) => {
  if (reset) {
    state = {
      ...state,
      sessionData,
    };
  } else
    state = {
      ...state,
      sessionData: {
        ...state.sessionData,
        ...sessionData,
      },
    };
};

export const getSessionData = () => state.sessionData;

export const resetStore = () => {
  state = initialState;
};

export const setProfileToken = (profileToken) =>
  (state = {
    ...state,
    profileToken,
  });

export const getProfileToken = () => state.profileToken;

export const setShadowRoot = (root)=>{
  shadowRoot = root;
}

export const getShadowRoot = ()=> {return shadowRoot ?? document.getElementById("identity-container").shadowRoot};

export const setApiKey = (apiKey)=>(state = {
  ...state,
  apiKey,
});

export const getApiKey = ()=>state.apiKey;

export const setinitSessionOptions = (initSessionOptions)=>(state = {
  ...state,
  initSessionOptions,
});

export const getinitSessionOptions = ()=>state.initSessionOptions;

export const setshowClose = (showClose) =>
  (state = {
    ...state,
    showClose,
  });

export const getshowClose = ()=>state.showClose;

export const setIsIdentityCalled = (IsIdentityCalled) =>
  (state = {
    ...state,
    IsIdentityCalled,
  });

export const getIsIdentityCalled = ()=>state.IsIdentityCalled;

export const setElement = (element) =>
  (state = {
    ...state,
    element,
  });

export const getElement = ()=>state.element;

export const setEventType = (eventType) =>
  (state = {
    ...state,
    eventType,
  });

export const getEventType = ()=>state.eventType;

export const getPlatform = ()=>state.platform;

export const setPlatform = (platform) =>
  (state = {
    ...state,
    platform,
  });

  export const setinitBillingAddr = (initBillingAddr)=>(state = {
    ...state,
    initBillingAddr,
  });
  
  export const getinitBillingAddr = ()=>state.initBillingAddr;

  export const setallowEditing = (allowEditing)=>(state = {
    ...state,
    allowEditing,
  });
  
  export const getallowEditing = ()=>state.allowEditing;